<template>
    <section class="full about" v-if="otoMy" :style="{ backgroundImage: `url(${this.otoMy.imagePath})`} ">
        <div class="container col4">
            <div class="box">
                <picture><img src="@/assets/img/rzeszowska_napis-300x104.png" alt="" /></picture>
            </div>
            <div class="box text">
                <div class="top fade fadein fadeinstart">
                    <header class="medium">
                        <span class="subheader">{{ otoMy.subheader }}</span>
                        <h2>{{ otoMy.header }}</h2>
                    </header>
                </div>
                <div class="center fade fadein fadeinstart">
                    <div v-html="otoMy.content">
                    </div>
                </div>
                <div class="bottom fade fadein fadeinstart">
                    <a :href="otoMy.href" class="link">o nas</a>
                </div>
            </div>
            <div class="box">
                <picture><img src="@/assets/img/spolka.png" alt="" /></picture>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            otoMy: null
        };
    },
    computed: {
        aboutBgImagePath() {
            let valToReturn = "none"
            if(this.otoMy &&  this.otoMy.imagePath) {
                valToReturn =  `url(${this.otoMy.imagePath})`
            }
            return valToReturn
        },
    },
    name: "FullBgCenterText",
    props: ["pageData"],
    methods: {
        getOtoMy() {
            // axios.get("/api/otoMy/otoMy.php").then((response) => {
                axios.get("https://rzeszowska.org.pl/api/otoMy/otoMy.php").then((response) => {
                // axios.get("http://localhost:3000/otoMy").then((response) => {
                this.otoMy = response.data;
            });
        },
    },
    mounted() {
        this.getOtoMy();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";


.about {
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    padding: 0;
    .box {
        &.text {
            display: flex;
            flex-direction: column;
            background: $colorGrey2B;
            color: $colorWhite;
            align-items: center;
            .top {
                header {
                    h2 {
                        color: $colorWhite;
                    }
                }
            }
            .center {
                p {
                    color: $colorWhite;
                }
            }
        }
    }
    @media screen and (max-width: $tablet) {
        .col4 {
            gap: 0;
        }
        .box {
            padding: 30px;
            &.text {
                width: 50%;
            }
        }
    }
    @media screen and (max-width: $smalltablet) {
        .col4 {
            flex-wrap: wrap;
        }
        .box {
            order: 2;
            width: 50%;
            padding: 15px;
            &.text {
                width: 100%;
                order: 1;
            }
        }
    }
    @media screen and (max-width: $mobile) {
        .col4 {
            flex-direction: row;
        }
    }
}

a.link {
    display: inline-block;
    margin: 15px 0;
    color: inherit;
    text-decoration: none;
    max-width: 100%;
    border: 1px solid $colorWhite;
    color: rgb(255, 255, 255);
    opacity: 1;
    display: inline-block;
    @include fontSize14px;
    text-transform: uppercase;
    color: inherit;
    cursor: pointer;
    padding: 25px 80px;
    text-align: center;
    position: relative;
    transition: ease-in all 0.3s;
    overflow: hidden;
    z-index: 1;
    max-width: 100%;
    visibility: visible;
}
</style>
