<template>
    <section class="form kontakt" data-space="0">
        <span class="komunikat potwierdzenie" :class="{ active: potwierdzenie }" style="display: none" ref="formKomunikat">
            {{ komunikat }}
        </span>
        <form action="#" class="formularz-kontakt fade fadeinleft fadeinleftstart" method="post" :class="{ hide: potwierdzenie }">
            <fieldset>
                <legend>Formularz</legend>
                <div class="field">
                    <label for="kontakt_imie">Imię</label>
                    <input ref="error_imie" v-on:input="change" required v-model="kontakt_imie" type="text" id="kontakt_imie" class="kontakt_imie" name="kontakt[kontakt_imie]" placeholder="Imię" />
                    <p class="blad" v-if="formErrors[0].error_imie.length">
                        {{ formErrors[0].error_imie }}
                    </p>
                </div>
                <div class="field">
                    <label for="kontakt_email">E-mail</label>
                    <input ref="error_email" v-on:input="change" required v-model="kontakt_email" type="email" name="kontakt[kontakt_email]" id="kontakt_email" class="kontakt_email" placeholder="E-mail" />
                    <p class="blad" v-if="formErrors[0].error_email.length">
                        {{ formErrors[0].error_email }}
                    </p>
                </div>
                <div class="field">
                    <label for="kontakt_temat">Temat wiadomości</label>
                    <input ref="error_temat" v-on:input="change" required v-model="kontakt_temat" type="tel" name="kontakt[kontakt_temat]" id="kontakt_temat" class="kontakt_temat" placeholder="Temat" />
                    <p class="blad" v-if="formErrors[0].error_temat">
                        {{ formErrors[0].error_temat }}
                    </p>
                </div>
                <div class="field">
                    <label for="kontakt_tresc">Wiadomość</label>
                    <textarea ref="error_tresc" v-on:input="change" required v-model="kontakt_tresc" id="kontakt_tresc" class="kontakt_tresc" name="kontakt[kontakt_tresc]" placeholder="Wiadomość"></textarea>
                    <p class="blad" v-if="formErrors[0].error_tresc.length">
                        {{ formErrors[0].error_tresc }}
                    </p>
                </div>
                <div class="field agreement">
                    <div
                        class="choice"
                        @click="
                            kontakt_zgoda = !kontakt_zgoda;
                            change();
                        "
                    >
                        <div class="checkbox">
                            <button ref="error_zgoda" class="donotfade check zgoda1" :class="{ active: kontakt_zgoda }" type="button" title="Kliknij, by wyrazić zgodę na przetwarzanie danych"></button>
                        </div>
                        <p class="zgoda">Przeczytałem/am <a href="/upload/pliki/kontakt/20180523_FRz_zasady_ochrony_dany.pdf" target="_blank">Zasady ochrony danych osobowych</a> i wyrażam zgodę na przetwarzanie danych osobowych na potrzeby udzielenia odpowiedzi na moją wiadomość</p>
                        <p class="blad" v-if="formErrors[0].error_zgoda">
                            {{ formErrors[0].error_zgoda }}
                        </p>
                    </div>
                </div>
                <button type="button" class="send" :disabled="sent" @click="checkForm">Wyślij wiadomość</button>
            </fieldset>
        </form>
    </section>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            sent: false,
            kontakt_imie: "",
            kontakt_email: "",
            kontakt_temat: "",
            kontakt_tresc: "",
            kontakt_zgoda: "",
            komunikat: "",
            potwierdzenie: false,
            formErrors: [
                {
                    error_imie: "",
                    error_email: "",
                    error_temat: "",
                    error_tresc: "",
                    error_zgoda: "",
                },
            ],
        };
    },
    name: "Form",
    methods: {
        change() {
            if (this.kontakt_imie.length === 0) {
            } else {
                this.formErrors[0].error_imie = "";
            }
            if (this.kontakt_email.length === 0) {
            } else {
                this.formErrors[0].error_email = "";
            }
            if (this.kontakt_temat.length === 0) {
            } else {
                this.formErrors[0].error_temat = "";
            }
            if (this.kontakt_tresc.length === 0) {
            } else {
                this.formErrors[0].error_tresc = "";
            }
            if (this.kontakt_zgoda === false) {
            } else {
                this.formErrors[0].error_zgoda = "";
            }
        },
        checkForm: function (e) {
            e.preventDefault();
            axios
                .post("https://rzeszowska.org.pl/api/form/form.php", this.pobierzDaneFormularza)
                // .post("/api/form/form.php", this.pobierzDaneFormularza)
                .then((response) => {
                    var vm = this;

                    this.potwierdzenie = JSON.parse(JSON.stringify(response.data.potwierdzenie));
                    if (this.potwierdzenie === true) {
                        this.sent = true;
                        this.goto("formKomunikat");
                        // console.log("potwierdzenie jest true");
                        this.komunikat = JSON.parse(JSON.stringify(response.data.komunikat));
                    } else {
                        // console.log("potwierdzenie jest false");
                        var errors = JSON.parse(JSON.stringify(response.data.error));

                        var errorFocus = Object.keys(errors)[0];
                        this.$refs[errorFocus].focus();

                        Object.keys(errors).map(function (key) {
                            vm.formErrors[0][key] = errors[key];
                        });
                    }
                })
                .catch((error) => {});
        },
        goto(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;

            window.scrollTo(0, top);
        },
        // submitForm() {},
    },
    computed: {
        pobierzDaneFormularza() {
            const daneZFormularza = {
                imie: this.kontakt_imie,
                email: this.kontakt_email,
                temat: this.kontakt_temat,
                tresc: this.kontakt_tresc,
                zgoda: this.kontakt_zgoda,
            };
            return daneZFormularza;
        },
    },
    mounted() {
        //empty
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

fieldset {
    height: 100%;
    position: relative;
    display: grid;
    gap: 20px;
    legend {
        display: none;
    }
    .field {
        width: 100%;
        position: relative;
        &--half {
            display: flex;
            input:first-of-type {
                border-right: none;
            }
        }
        label {
            display: none;
            margin-bottom: 8px;
            font-size: 16px;
        }
        input {
            border: 1px solid $colorDark;
            padding: 20px 24px;
            width: 100%;
            color: $colorDark;
            font-family: $fontLato;
            &::placeholder {
                font-weight: 300;
                color: $colorDark;
            }
        }
        textarea {
            border: 1px solid $colorDark;
            padding: 20px 24px;
            width: 100%;
            font-family: $fontLato;
            color: $colorDark;
            &::placeholder {
                font-weight: 300;
                color: $colorDark;
            }
        }
        .blad {
            color: red;
        }
    }
    .send {
        @include button;
        text-transform: lowercase;
        padding: 23px 22px;
        margin: 0 auto;
        font-size: 16px;
        color: $colorDark;
        border: 1px solid $colorDark;
        text-align: center;
        transition: all 0.3s ease-in;
        max-width: 188px;
        position: relative;
        @include aEffect;
        background-color: $colorWhite;
        &::before {
            background: darken($colorDark, 0);
        }
        &:hover {
            z-index: 2;
            color: $colorWhite;
            &::before {
                width: 100%;
            }
        }
    }
    .choice {
        display: flex;
        flex-wrap: wrap;
        .zgoda {
            display: block;
            width: calc(100% - 50px);
        }
        input {
            display: none;
        }
        .checkbox {
            button {
                @include button;
                display: block;
                height: 30px;
                width: 30px;
                transition: all 0.3s;
                position: relative;
                margin-right: 20px;
                border: 1px solid $colorDark;
                &:after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    content: "";
                    background: url(../assets/img/svg/icon-checked.svg);
                    background-repeat: no-repeat;
                    background-position: 50%;
                    background-size: 55% auto;
                    transition: all 0.3s ease;
                    opacity: 0;
                }
            }
            .active {
                &:after {
                    opacity: 1;
                }
            }
        }
        span {
            padding: 0;
            cursor: pointer;
            display: block;
            text-align: left;
            font-size: 11px;
            width: calc(100% - 50px);
        }
        p {
            width: 100%;
        }
        a {
            color: $colorGreen;
            font-weight: 700;
        }
    }
}
</style>
