<template>
    <article class="event fade fadeinbottom fadeinbottomstart" v-if="person">
        <div>
            <div class="picture">
                <picture>
                    <img v-if="person.zdjecie" :src="`/upload/pictures/${person.zdjecie}`" :alt="person.imie" />
                    <img v-else :src="`/upload/pictures/icon-female-380x340.jpg`" :alt="person.imie" />
                </picture>
                <div class="overlay">
                    <header class="small">
                        <h2>{{ person.imie }} {{ person.nazwisko }}</h2>
                        <span class="subheader">{{ person.dodOpis }}</span>
                    </header>
                    <div class="social" v-if="person.socialFb || person.socialInsta || person.socialLinkedin">
                        <a v-if="person.socialFb" :href="person.socialFb" :class="{ fb: person.socialFb }"></a>
                        <a v-if="person.socialInsta" :href="person.socialInsta" :class="{ insta: person.socialInsta }"></a>
                        <a v-if="person.socialLinkedin" :href="person.socialLinkedin" :class="{ linkedin: person.socialLinkedin }"></a>
                    </div>
                </div>
            </div>

            <header class="small">
                <h2>{{ person.imie }} {{ person.nazwisko }}</h2>
                <span class="subheader">{{ person.podpis }}</span>
            </header>
        </div>
        <p v-if="person.dodOpis != ''">{{ person.dodOpis }}</p>
    </article>
</template>

<script>
export default {
    
    name: "Osoba-box",
    props: ["pageData", "person"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.events {
    &.spacer {
        @include padding100Bottom;
    }
    &.people {
        h3 {
            text-align: center;
            font-weight: 300;
            @include fontSize25px;
            white-space: pre-line;
            padding: 0;
        }
    }
    .archive {
        @include padding40Top;
    }
    h3 {
        @include fontSize25px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: left;
    }
    .event {
        position: relative;
        overflow: hidden;
        height: 100%;
        width: 100%;
        &:hover {
            .picture {
                .overlay {
                    opacity: 1;
                    top: 0;
                }
            }
            & > div > header {
                visibility: hidden;
            }
        }
        p {
            @include fontSize14px;
        }
        span {
            @include fontSize16px;
            font-style: italic;
            display: block;
        }
        .subheader {
            color: $colorGrey3C;
        }
        a {
            width: 100%;
            z-index: 9;
            &:hover {
                p {
                    top: 50%;
                }
            }
        }
        .picture {
            position: relative;
            overflow: hidden;
            margin-bottom: 15px;
            .overlay {
                content: "";
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-color: rgba($color: $colorGrey3C, $alpha: 0.6);
                width: 100%;
                height: 100%;
                padding: 15px;
                mix-blend-mode: hard-light;
                z-index: 99;
                transition: 0.3s ease;
                top: 80px;
                bottom: 0;
                opacity: 0;
                left: 0;
                header {
                    h2 {
                        color: $colorWhite;
                    }
                    span {
                        color: $colorWhite;
                    }
                }
                .social {
                    display: flex;
                    gap: 5px;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    width: 100%;
                    margin: 0;
                }
                a {
                    position: relative;
                    display: block;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 35px 35px;
                    height: 44px;
                    width: 44px;
                    overflow: hidden;
                    &:hover {
                        opacity: 1;
                        &:after {
                            left: 100%;
                            transition: left 0.3s ease-in-out;
                        }
                    }
                    &.insta {
                        background-image: url(../assets/img/svg/icon-inst.svg);
                    }
                    &.fb {
                        background-image: url(../assets/img/svg/icon-fb.svg);
                    }
                    &.linkedin {
                        background-image: url(../assets/img/svg/icon-yt.svg);
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        width: 40px;
                        height: 1px;
                        background: white;
                        top: 48%;
                        left: -125%;
                        -webkit-transition: left 0s ease-in-out;
                        -moz-transition: left 0s ease-in-out;
                        transition: left 0s ease-in-out;
                    }
                }
            }
            picture {
                background-color: white;
                display: flex;
                justify-content: center;
                width: 100%;
                // img {
                //     max-height: 340px;
                //     max-width: 380px;
                // }
            }
        }
    }
}
</style>
