<template>
    <section class="events spacer">
        <header class="subpage-header subpage-header--small" :style="{ backgroundImage: fullSectionImagePath }">
            <h1 class="content__header fade fadein fadeinstart">{{ pageData.title }}</h1>
        </header>
        <div class="container">
            <header class="medium fade fadein fadeinstart">
                <h2>Zobacz czym się zajmujemy</h2>
            </header>
            <div class="row row30">
                <div class="col col4 fade fadeinbottom fadeinbottomstart" v-for="(initiative, index) in inicjatywyAutorskieAktualne" :key="index + 'aa'">
                    <Initiative :typInicjatyw="initiative"></Initiative>
                </div>
            </div>
            <div class="row row30">
                <div class="col col4 fade fadeinbottom fadeinbottomstart" v-for="(initiative, index) in inicjatywyPartnerskieAktualne" :key="index + 'pa'">
                    <Initiative :typInicjatyw="initiative"></Initiative>
                </div>
                
                <div class="col col4 fade fadeinbottom fadeinbottomstart" v-for="(initiative, index) in inicjatywyAutorskieArchiwalne" :key="index + 'aar'">
                    <Initiative :typInicjatyw="initiative"></Initiative>
                </div>
                
                <div class="col col4 fade fadeinbottom fadeinbottomstart" v-for="(initiative, index) in inicjatywyPartnerskieArchiwalne" :key="index + 'pa'">
                    <Initiative :typInicjatyw="initiative"></Initiative>
                </div>
            </div>
            <!-- <Gallery v-if="pageData.gallery" :galleryItems="zmodyfikowaneDaneDoGalerii" /> -->
            <!-- lewa strona propsa to jest nowy props, który trzeba zapowiedzieć w dziecku. A prawa strona to są jakieś dane, które są w TYM komponencie (patrz w tym przykładzie na computed) -->
            <!-- metody odpalam sama w mounted albo w innej metodzie, a computed odpala się samo wtedy, kiedy jedna z jego części zmieniła się w aplikacji (bo przelicza) np. pobrałam pageData z serwera -->
        </div>
    </section>
</template>

<script>
import axios from "axios";
import Initiative from "@/components/Initiative";
// import Gallery from "@/components/Gallery";

export default {
    data() {
        return {
            // Full: null,
            // Zarzad: null,
            // Kolektyw: null,
            inicjatywyAutorskieAktualne: [],
            inicjatywyAutorskieArchiwalne: [],
            inicjatywyPartnerskieAktualne: [],
            inicjatywyPartnerskieArchiwalne: [],
        };
    },
    components: {
        Initiative: Initiative,
        // Gallery: Gallery,
    },
    name: "Inicjatywy-wszystkie",
    props: ["pageData", "initiative"],
    computed: {
        fullSectionImagePath() {
            let valToReturn = "none";
            if (this.pageData && this.pageData.imagePath) {
                valToReturn = `url(${this.pageData.imagePath})`;
            }
            return valToReturn;
        },
        zmodyfikowaneDaneDoGalerii() {
            const currentGalleryData = this.pageData.gallery.items;
            const newGalleryData = [];
            currentGalleryData.forEach((item) => {
                const newItem = {
                    imagePath: `upload/pictures/${item.zdjecie_sciezka}.webp`,
                    imagePathMobile: `upload/pictures/mobile/${item.zdjecie_sciezka}.webp`,
                    imagePathNormal: `upload/pictures/normal/${item.zdjecie_sciezka}.webp`,
                    imageAlt: item.alt,
                    imageTitle: item.title
                };
                newGalleryData.push(newItem);
            });
            return newGalleryData;
        },
    },
    methods: {
        getInicjatywy() {
            // axios.get("/api/fullSection/fullSection.php").then((response) => {
            axios.get("https://rzeszowska.org.pl/api/inicjatywy/lista.php").then((response) => {
                // axios.get("http://localhost:3000/inicjatywy").then((response) => {
                this.inicjatywyAutorskieAktualne = response.data.autorskieAktualne;
                this.inicjatywyAutorskieArchiwalne = response.data.autorskieArchiwalne;
                this.inicjatywyPartnerskieAktualne = response.data.partnerskieAktualne;
                this.inicjatywyPartnerskieArchiwalne = response.data.partnerskieArchiwalne;

                // this.inicjatywy = response.data;
            });
        },
    },
    mounted() {
        this.getInicjatywy();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";
</style>
