<template>
    <div>
        <div v-if="pageData">
            <h1 style="display: none;">Fundacja Rzeszowska</h1>
            <Slider :data="sliderData"></Slider>
            <Full :daneDoWyswietlenia="naglowek1" class="subtitle white"></Full>
            <FullBgCenterText></FullBgCenterText>
            <GlownaListaAktualnosci></GlownaListaAktualnosci>
            <Full :daneDoWyswietlenia="naglowek2" class="slide"></Full>
            <Marki></Marki>
            <Osiagniecia></Osiagniecia>
            <GlownaListaInicjatyw></GlownaListaInicjatyw>
            <Partnerzy></Partnerzy>
            <Full :daneDoWyswietlenia="naglowek3" class="dark donation"></Full>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Full from "@/components/Full";
import Slider from "@/components/Slider";
import FullBgCenterText from "@/components/FullBgCenterText";
import GlownaListaAktualnosci from "@/components/GlownaListaAktualnosci";
import Marki from "@/components/Marki";
import Osiagniecia from "@/components/Osiagniecia";
import GlownaListaInicjatyw from "@/components/GlownaListaInicjatyw";
import Partnerzy from "@/components/Partnerzy";

export default {
    data() {
        return {
            fullSection: [],
            sliderData: null,
        };
    },
    components: {
        Full: Full,
        Slider: Slider,
        FullBgCenterText: FullBgCenterText,
        GlownaListaAktualnosci: GlownaListaAktualnosci,
        Marki: Marki,
        Osiagniecia: Osiagniecia,
        GlownaListaInicjatyw: GlownaListaInicjatyw,
        Partnerzy: Partnerzy,
    },
    name: "Glowna-strona",
    props: ["pageData"],
    computed: {
        naglowek1() {
            const heading = this.fullSection[0];
            return heading;
        },
        naglowek2() {
            const heading = this.fullSection[1];
            return heading;
        },
        naglowek3() {
            const heading = this.fullSection[2];
            return heading;
        },
    },
    methods: {
        getSliderData() {
            // axios.get("/api/slider/slider.php").then((response) => {
            // axios.get("http://localhost:3000/slider").then((response) => { // adres z lokalnej bazy danych
            axios
                .get("https://rzeszowska.org.pl/api/slider/slider.php")
                .then((response) => {
                    this.sliderData = response.data;
                });
        },
        getFullSection() {
            // axios.get("/api/fullSection/fullSection.php").then((response) => {
            axios
                .get(
                    "https://rzeszowska.org.pl/api/fullSection/fullSection.php"
                )
                .then((response) => {
                    // axios.get("http://localhost:3000/fullSection").then((response) => {
                    this.fullSection = response.data;
                });
        },
    },
    created() {
        this.getSliderData();
        this.getFullSection();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.laduje-sie {
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.activity {
    background: $colorGray;
    &__header {
        h2 {
            color: $colorWhite;
        }
    }
    &-container {
        display: flex;
        flex-direction: column;
        gap: 40px;
        article {
            display: flex;
            &:nth-of-type(2n+2) {
                flex-direction: row-reverse;
            }
            .col6 {
                width: 50%;
                background-size: cover;
                background-position: center;
                min-height: 400px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .subheader {
                    @include fontSize14px;
                }
                header {
                    h3 {
                        color: $colorWhite;
                        @include fontSize25px;
                    }
                    span {
                        color: $colorWhite;
                    }
                }
                &.title {
                    border: 20px solid $colorWhite;
                    padding: 40px;
                }
                // height: 100%;
                // width: auto;
            }
            .links {
                padding: 30px 0 0 0;
                width: fit-content;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                gap: 15px;
                a {
                    padding: 10px 20px;
                    border: 1px solid $colorWhite;
                    color: $colorWhite;
                    text-transform: uppercase;
                    transition: ease-in all 0.3s;
                    position: relative;
                    z-index: 1;
                    @include aEffect;
                }
            }
            @media screen and (max-width: $smalltablet) {
                flex-direction: column;
                .col6 {
                    width: 100%;
                    min-height: 250px;
                }
                &:nth-of-type(2n+2) {
                    flex-direction: column;
                }
            }
        }
    }
}
</style>
