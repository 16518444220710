<template>
    <article class="event" v-if="typInicjatyw">
        <router-link :to="typInicjatyw.link">
            <div class="picture">
                <picture
                    ><img :src="typInicjatyw.imagePath" :alt="`${typInicjatyw.header} - baner`"
                /></picture>
                <div class="overlay">
                    <header class="small">
                        <h2>{{ typInicjatyw.header }}</h2>
                        <span class="subheader">{{ typInicjatyw.subheader }}</span>
                    </header>
                </div>
            </div>
            <header class="small">
                <h2>{{ typInicjatyw.header }}</h2>
                <span class="subheader">{{ typInicjatyw.subheader }}</span>
            </header>
        </router-link>
    </article>
</template>

<script>
// import Gallery from '@/components/Gallery.vue';

export default {
    data() {
        return {
            // ludzie: [],
        };
    },
    name: "Inicjatywy-trzy",
    props: ["pageData", "typInicjatyw", "initiative"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.events {
    &.spacer {
        @include padding100;
    }
    &.people {
        h3 {
            text-align: center;
            font-weight: 300;
            @include fontSize25px;
            white-space: pre-line;
            padding: 0;
        }
    }
    .archive {
        @include padding40Top;
    }
    h3 {
        @include fontSize25px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: left;
    }
    .event {
        position: relative;
        overflow: hidden;
        &:hover {
            .picture {
                .overlay {
                    opacity: 1;
                    top: 0;
                }
            }
            a > header {
                visibility: hidden;
            }
        }
        p {
            @include fontSize14px;
        }
        span {
            @include fontSize16px;
            font-style: italic;
            display: block;
        }
        a {
            width: 100%;
            z-index: 9;
            &:hover {
                p {
                    top: 50%;
                }
            }
        }
        .picture {
            position: relative;
            overflow: hidden;
            margin-bottom: 15px;
            .overlay {
                content: "";
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-color: rgba($color: $colorGrey3C, $alpha: 0.6);
                width: 100%;
                height: 100%;
                padding: 15px;
                mix-blend-mode: hard-light;
                z-index: 99;
                transition: 0.3s ease;
                top: 80px;
                bottom: 0;
                opacity: 0;
                left: 0;
                header {
                    h2 {
                        color: $colorWhite;
                    }
                    span {
                        color: $colorWhite;
                    }
                }
                .social {
                    display: flex;
                    gap: 5px;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    width: 100%;
                    margin: 0;
                }
                a {
                    position: relative;
                    display: block;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 28px 28px;
                    height: 28px;
                    width: 28px;
                    &.insta {
                        background-image: url(../assets/img/svg/icon-inst.svg);
                    }
                    &.fb {
                        background-image: url(../assets/img/svg/icon-fb.svg);
                    }
                    &.linkedin {
                        background-image: url(../assets/img/svg/icon-yt.svg);
                    }
                }
            }
            picture {
                background-color: white;
                display: flex;
                justify-content: center;
                width: 100%;
                // img {
                //     max-height: 340px;
                //     max-width: 380px;
                // }
            }
        }
    }
}
</style>
