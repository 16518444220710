<template>
    <aside class="partners">
        <div class="container">
            <div class="partners-partners">
                <header class="medium"><h2>PARTNERZY</h2></header>
                <ul>
                    <li v-for="(partner, index) in partnerzy" :key="index">
                        <a v-if="partner.href != ''" :href="partner.href" target="_blank" rel="nofollow"><picture><img :src="partner.imagePath" :alt="partner.alt" /></picture></a>
                        <picture v-else><img :src="partner.imagePath" :alt="partner.alt" /></picture>
                    </li>
                </ul>
            </div>
            <div class="partners-support">
                <header class="medium"><h2>WSPIERAJĄ nas</h2></header>
                <ul>
                    <li v-for="(partner, index) in wsparcie" :key="index">
                        <a v-if="partner.href != ''" :href="partner.href" target="_blank" rel="nofollow"><picture><img :src="partner.imagePath" :alt="partner.alt" /></picture></a>
                        <picture v-else><img :src="partner.imagePath" :alt="partner.alt" /></picture>
                    </li>
                </ul>
            </div>
        </div>
    </aside>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            partnerzy: [],
            wsparcie: []
        };
    },
    computed: {
        
    },
    name: "Partnerzy-sekcja",
    methods: {
        getBanery() {
            // axios.get("/api/fullSection/fullSection.php").then((response) => {
            axios.get("https://rzeszowska.org.pl/api/banery/banery.php").then((response) => {
            // axios.get("http://localhost:3000/partnerzy").then((response) => {
                this.partnerzy = response.data.partnerzy;
                this.wsparcie = response.data.wsparcie;
            });
        },
    },
    mounted() {
        this.getBanery();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";
.partners {
    background: #fff;
    @include padding100;
    header {
        h2 {
            @include fontSize25px;
        }
    }
    
    &-support {
        @include padding80Top;
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 20px 0;
        li {
            position: relative;
            width: calc(100% / 7);
            padding: 0 20px;
            a {
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                transition: 0.2s ease-in opacity;
                &:hover {
                    opacity: 0.7;
                }
            }
            picture {
                width: 100%;
                height: auto;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-width: 100%;
                    max-height: 64px;
                    width: auto;
                }
            }
        }
        @media screen and (max-width: $bigtablet) {
            li {
                width: calc(100% / 6);
            }
        }
        @media screen and (max-width: $smalltablet) {
            li {
                // max-width: calc(33% - 40px);
                width: calc(100% / 4);
                picture {
                    img {
                        // max-width: unset;
                        // max-height: unset;
                    }
                }
            }
        }
        @media screen and (max-width: $mobile) {
            justify-content: space-evenly;
            gap: 12px 0;
            li {
                width: calc(100% / 3);
                picture {
                    img {
                        max-height: 40px;
                    }
                }
            }
        }
    }
}
</style>
