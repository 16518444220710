/**
 * jQuery Plugin to obtain touch gestures from iPhone, iPod Touch and iPad, should also work with Android mobile phones (not tested yet!)
 * Common usage: wipe images (left and right to show the previous or next image)
 *
 * @author Andreas Waltl, netCU Internetagentur (http://www.netcu.de)
 * @version 1.1.1 (9th December 2010) - fix bug (older IE's had problems)
 * @version 1.1 (1st September 2010) - support wipe up and wipe down
 * @version 1.0 (15th July 2010)
 */
 (function($){$.fn.touchwipe=function(settings){var config={min_move_x:20,min_move_y:20,wipeLeft:function(){},wipeRight:function(){},wipeUp:function(){},wipeDown:function(){},preventDefaultEvents:true};if(settings)$.extend(config,settings);this.each(function(){var startX;var startY;var isMoving=false;function cancelTouch(){this.removeEventListener('touchmove',onTouchMove);startX=null;isMoving=false}function onTouchMove(e){if(config.preventDefaultEvents){e.preventDefault()}if(isMoving){var x=e.touches[0].pageX;var y=e.touches[0].pageY;var dx=startX-x;var dy=startY-y;if(Math.abs(dx)>=config.min_move_x){cancelTouch();if(dx>0){config.wipeLeft()}else{config.wipeRight()}}else if(Math.abs(dy)>=config.min_move_y){cancelTouch();if(dy>0){config.wipeDown()}else{config.wipeUp()}}}}function onTouchStart(e){if(e.touches.length==1){startX=e.touches[0].pageX;startY=e.touches[0].pageY;isMoving=true;this.addEventListener('touchmove',onTouchMove,false)}}if('ontouchstart'in document.documentElement){this.addEventListener('touchstart',onTouchStart,false)}});return this}})(jQuery);

 /*
          _       _       _                  _                       _
  ____ __| | ____(_) ___ | |  ___ __      __(_)  ___  ____    _ __  | |
 |_  // _` ||_  /| |/ __|| | / _ \\ \ /\ / /| | / __||_  /   | '_ \ | |
  / /| (_| | / / | |\__ \| || (_) |\ V  V / | || (__  / /  _ | |_) || |
 /___|\__,_|/___||_||___/|_| \___/  \_/\_/  |_| \___|/___|(_)| .__/ |_|
                                                             |_|
 
 SLIDER PLUGIN
 www.zdzislowicz.pl
 biuro@zdzislowicz.pl
 
 all rights reserved
 */
 
  $(function () {

    var zSliderStop = 0;
    //  zSliderStop = getCookie('zdz-wcag-disableAnimations');
     
     if(zSliderStop == null)
         zSliderStop = 0;
     
     $.fn.zSlider = function(options) {

         var settings = $.extend({
             speed : 5000,
             duration : 1000,
             show: 0,
             hide: 0,
             startDelay: 0,
             progressBar: false
         }, options);
     
         var timeout;
         var element = this;
     
         if(settings.progressBar)
             element.append('<div class="progressBar"><div></div></div>');
     
         if(element.find('.content li').length > 0)
             element.find('.content li:eq(0)').stop(true, true).fadeIn(settings.duration);
     
         if(element.find('.content li').length > 2)
         {
             if(settings.progressBar)
                 $(element).find('.progressBar div').animate({ width: '100%' }, settings.speed, 'linear');
     
             setTimeout(function() {
                 timeout = setTimeout(function() {
                     run(element);
                 },
                 settings.speed);
             }, settings.startDelay);
         }
     
         function run(element) {
     
             if(zSliderStop == 0)
             {
                 settings.hide = settings.show;
                 settings.show++;
     
                 if(settings.show == (element.find('.content li').length - 1))
                     settings.show = 0;
     
                 element.find('.nav li:eq(' + settings.show + ')').children().addClass('active');
                 element.find('.nav li:eq(' + settings.hide + ')').children().removeClass('active');
     
                 element.find('.content li:eq(' + settings.show + ')').stop(true, true).fadeIn(settings.duration)
                 element.find('.content li:eq(' + settings.hide + ')').stop(true, true).fadeOut(settings.duration);
                 
                 $('body').find('.content-bg li:eq(' + settings.show + ')').stop(true, true).fadeIn(settings.duration);
                 $('body').find('.content-bg li:eq(' + settings.hide + ')').stop(true, true).fadeOut(settings.duration);
     
                 if(settings.progressBar)
                 {
                     $(element).find('.progressBar div').animate({ width: '0%' }, 0, 'linear');
                     $(element).find('.progressBar div').animate({ width: '100%' }, settings.speed, 'linear');
                 }
     
                 $('.pagination-info b').html(settings.show);        
     
                 clearTimeout(timeout);
                 timeout = setTimeout(function(){ run(element); }, settings.speed);
             }
         }
     
         element.on('click', '.nav li', function(e) {
             e.preventDefault();
             e.stopPropagation();
     
             settings.hide = settings.show;
             settings.show = $( ".nav li" ).index(this);
     
             if(settings.show != settings.hide)
             {
                 element.find('.nav li:eq(' + settings.show + ')').children().addClass('active');
                 element.find('.nav li:eq(' + settings.hide + ')').children().removeClass('active');
     
                 element.find('.content li:eq(' + settings.show + ')').stop(true, true).fadeIn(settings.duration)
                 element.find('.content li:eq(' + settings.hide + ')').stop(true, true).fadeOut(settings.duration);
     
                 $('body').find('.content-bg li:eq(' + settings.show + ')').stop(true, true).fadeIn(settings.duration);
                 $('body').find('.content-bg li:eq(' + settings.hide + ')').stop(true, true).fadeOut(settings.duration);            
     
                 $('.pagination-info b').html(settings.show);
     
                 clearTimeout(timeout);
                 timeout = setTimeout(function(){ run(element); }, settings.speed);
             }
     
             if(settings.progressBar)
             {
                 $(element).find('.progressBar div').stop(true, true).animate({ width: '0%' }, 0, 'linear');
                 $(element).find('.progressBar div').stop(true, true).animate({ width: '100%' }, settings.speed, 'linear');
             }          
         });
     
         element.on('click', '.next', function(e) {
             settings.hide = settings.show;
             settings.show++;
     
             if(settings.show == (element.find('.content li').length - 1))
                 settings.show = 0;
     
             element.find('.nav li:eq(' + settings.show + ')').children().addClass('active');
             element.find('.nav li:eq(' + settings.hide + ')').children().removeClass('active');
     
             element.find('.content li:eq(' + settings.show + ')').stop(true, true).fadeIn(settings.duration)
             element.find('.content li:eq(' + settings.hide + ')').stop(true, true).fadeOut(settings.duration);
             
             $('body').find('.content-bg li:eq(' + settings.show + ')').stop(true, true).fadeIn(settings.duration);
             $('body').find('.content-bg li:eq(' + settings.hide + ')').stop(true, true).fadeOut(settings.duration);        
     
             if(settings.progressBar)
             {
                 $(element).find('.progressBar div').stop(true, true).animate({ width: '0%' }, 0, 'linear');
                 $(element).find('.progressBar div').stop(true, true).animate({ width: '100%' }, settings.speed, 'linear');
             }
     
             $('.pagination-info b').html(settings.show);
     
             clearTimeout(timeout);
             timeout = setTimeout(function(){ run(element); }, settings.speed);
         });    
     
         element.on('click', '.prev', function(e) {
             settings.hide = settings.show;
             settings.show--;
     
             if(settings.show == -1)
                 settings.show = element.find('.content li').length-2;
     
             element.find('.nav li:eq(' + settings.show + ')').children().addClass('active');
             element.find('.nav li:eq(' + settings.hide + ')').children().removeClass('active');
     
             element.find('.content li:eq(' + settings.show + ')').stop(true, true).fadeIn(settings.duration)
             element.find('.content li:eq(' + settings.hide + ')').stop(true, true).fadeOut(settings.duration);
             
             $('body').find('.content-bg li:eq(' + settings.show + ')').stop(true, true).fadeIn(settings.duration);
             $('body').find('.content-bg li:eq(' + settings.hide + ')').stop(true, true).fadeOut(settings.duration);        
     
             if(settings.progressBar)
             {
                 $(element).find('.progressBar div').stop(true, true).animate({ width: '0%' }, 0, 'linear');
                 $(element).find('.progressBar div').stop(true, true).animate({ width: '100%' }, settings.speed, 'linear');
             }
     
             $('.pagination-info b').html(settings.show);        
     
             clearTimeout(timeout);
             timeout = setTimeout(function(){ run(element); }, settings.speed);
         });     
     
         /*
         element.touchwipe({
             wipeRight: function() {
                 if(element.find('.content li').length > 2)
                 {
                     settings.hide = settings.show;
                     settings.show--;
             
                     if(settings.show == -1)
                         settings.show = element.find('.content li').length-2;
             
                     element.find('.nav li:eq(' + settings.show + ')').children().addClass('active');
                     element.find('.nav li:eq(' + settings.hide + ')').children().removeClass('active');
             
                     element.find('.content li:eq(' + settings.show + ')').stop(true, true).fadeIn(settings.duration)
                     element.find('.content li:eq(' + settings.hide + ')').stop(true, true).fadeOut(settings.duration);
                     
                     $('body').find('.content-bg li:eq(' + settings.show + ')').stop(true, true).fadeIn(settings.duration);
                     $('body').find('.content-bg li:eq(' + settings.hide + ')').stop(true, true).fadeOut(settings.duration);            
     
                     if(settings.progressBar)
                     {
                         $(element).find('.progressBar div').stop(true, true).animate({ width: '0%' }, 0, 'linear');
                         $(element).find('.progressBar div').stop(true, true).animate({ width: '100%' }, settings.speed, 'linear');
                     }
                     $('.pagination-info b').html(settings.show);
     
                     clearTimeout(timeout);
                     timeout = setTimeout(function(){ run(element); }, settings.speed);
                 }
             },
             wipeLeft: function() {
                 if(element.find('.content li').length > 2)
                 {
                     settings.hide = settings.show;
                     settings.show++;
             
                     if(settings.show == (element.find('.content li').length - 1))
                         settings.show = 0;
             
                     element.find('.nav li:eq(' + settings.show + ')').children().addClass('active');
                     element.find('.nav li:eq(' + settings.hide + ')').children().removeClass('active');
             
                     element.find('.content li:eq(' + settings.show + ')').stop(true, true).fadeIn(settings.duration)
                     element.find('.content li:eq(' + settings.hide + ')').stop(true, true).fadeOut(settings.duration);
                     
                     $('body').find('.content-bg li:eq(' + settings.show + ')').stop(true, true).fadeIn(settings.duration);
                     $('body').find('.content-bg li:eq(' + settings.hide + ')').stop(true, true).fadeOut(settings.duration);            
     
                     if(settings.progressBar)
                     {
                         $(element).find('.progressBar div').stop(true, true).animate({ width: '0%' }, 0, 'linear');
                         $(element).find('.progressBar div').stop(true, true).animate({ width: '100%' }, settings.speed, 'linear');
                     }
                     
                     $('.pagination-info b').html(settings.show);
                     
                     clearTimeout(timeout);
                     timeout = setTimeout(function(){ run(element); }, settings.speed);
                 }
             },
             preventDefaultEvents: false
        });  
        */  
     }
 });
 $(function () {
     $('.slider').zSlider({
         speed : 5000,
         duration : 800,
         startDelay: 3000
     });
 });