<template>
    <div v-if="pageData" class="textpage">
        <header class="subpage-header subpage-header--small" :style="{ backgroundImage: fullSectionImagePath }">
            <h1 class="content__header">{{ pageData.title }}</h1>
        </header>
        <div class="textContent fade fadein fadeinstart" v-if="pageData">
            <div class="container">
                <div v-html="pageData.content">
                </div>
                <div v-if="pageData.gallery">
                    <Gallery v-if="galleryItems" :galleryItems="zmodyfikowaneDaneDoGalerii" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Gallery from "@/components/Gallery";

export default {
    data() {
        return {
            textPages: null,
            galleryItems: null
        };
    },
    components: {
        Gallery: Gallery,
    },
    name: "Tekstowa-strona",
    props: ["pageData"],
    computed: {
        fullSectionImagePath() {
            let valToReturn = "none";
            if (this.daneDoWyswietlenia && this.daneDoWyswietlenia.imagePath) {
                valToReturn = `url(${this.daneDoWyswietlenia.imagePath})`;
            }
            return valToReturn;
        },
        zmodyfikowaneDaneDoGalerii() {
            const currentGalleryData = this.pageData.gallery.items;
            const newGalleryData = [];
            currentGalleryData.forEach((item) => {
                const newItem = {
                    imagePath: `upload/pictures/${item.zdjecie_sciezka}.webp`,
                    imagePathMobile: `upload/pictures/mobile/${item.zdjecie_sciezka}.webp`,
                    imagePathNormal: `upload/pictures/normal/${item.zdjecie_sciezka}.webp`,
                    imageAlt: item.alt,
                    imageTitle: item.title
                };
                newGalleryData.push(newItem);
            });
            return newGalleryData;
        },
    },
    methods: {
        getTextPages() {
            // axios.get("/api/textPages/textPages.php").then((response) => {
            axios.get("https://rzeszowska.org.pl/api/textPages/textPages.php").then((response) => {
            //axios.get("http://localhost:3000/textPages").then((response) => {
                this.textPages = response.data;
            });
        },
    },
    mounted() {
        this.getTextPages();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.textpage {
    @include padding100Bottom;
    .textContent {
        .container {
            // picture {
                // width: 100%;
                // height: auto !important;
                // margin-bottom: 50px;
                // img {
                    // width: 100%;
                    // height: auto;
                // }
            // }
           
            img {
                // width: 100%;
                height: auto !important;
                // margin: 15px 0;
            }
            p a img {
                margin: 0 auto;
            }
        }
        video {
            @media screen and (max-width: $tablet) {
                max-width: 100%;
                height: auto;
            }
        }
    }
}
</style>
