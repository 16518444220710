<template>
    <article>
        <div class="col col6 fade fadein fadeinstart" :style="{ backgroundImage: `url(${typInicjatyw.imagePath})` }"></div>
        <div class="col col6 title fade fadein fadeinstart">
            <div class="text">
                <header class="normal">
                    <h3>{{ typInicjatyw.header }}</h3>
                    <span class="subheader">{{ typInicjatyw.subheader }}</span>
                </header>
            </div>
            <div class="links">
                <!-- <router-link v-if="typInicjatyw.href" :to="typInicjatyw.href">{{ typInicjatyw.header }}</router-link> -->
                <router-link v-if="typInicjatyw.href" :to="typInicjatyw.href">O inicjatywie</router-link>
                <router-link v-if="typInicjatyw.hrefOutside" :to="typInicjatyw.hrefOutside">{{ typInicjatyw.headerOutside }}</router-link>
            </div>
        </div>
    </article>
</template>
<script>
// import axios from "axios";

export default {
    data() {
        return {
        };
    },
    components: {
    },
    name: "Inicjatywy-glowna",
    props: ["pageData", "initiativeMain", "typInicjatyw"],
    
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

</style>
