$(document).ready(function() {
    $(".fade").each(function(s) {
        var a = $(this).offset().top + $(this).outerHeight();
        $(window).scrollTop() + $(window).height() > a && $(this).hasClass("fadein") && $(this).addClass("fadeinstart")
    }),
    $(window).scroll(function() {
        zAnimationsRun();
    })
    zAnimationsRun();

    function zAnimationsRun() {
        let licznik = 0; 
        $(".fade").each(function(s) {
            licznik++;
            var a = $(this).offset().top;
            $(window).scrollTop() + $(window).height() / 4 * 3.5 > a && ($(this).hasClass("fadein") && $(this).addClass("fadeinstart"), $(this).hasClass("fadeinleft") && $(this).addClass("fadeinleftstart"), $(this).hasClass("fadeinright") && $(this).addClass("fadeinrightstart"), $(this).hasClass("fadeinbottom") && $(this).addClass("fadeinbottomstart"), $(this).hasClass("fadeintop") && $(this).addClass("fadeintopstart"), $(this).hasClass("fadeinopacity") && $(this).addClass("fadeinopacitystart"))
        })
    }
});