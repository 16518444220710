<template>
    <section class="full activity half">
        <div class="container">
            <div class="activity__header">
                <header class="medium fade fadein fadeinstart">
                    <span class="subheader">Zobacz nad czym aktualnie pracujemy</span>
                    <h2>korzystaj z naszych inicjatyw</h2>
                </header>
            </div>
        </div>
        <div class="container activity-container">
            <InicjatywyGlowna v-for="(initiativeMain, index) in inicjatywyGlowne" :key="index" :typInicjatyw="initiativeMain"></InicjatywyGlowna>
        </div>
        <router-link to="/inicjatywy/" class="news__all showAllBright" aria-label="Przejdź do strony z aktualnościami">Zobacz więcej inicjatyw</router-link>
    </section>
</template>
<script>
import axios from "axios";
import InicjatywyGlowna from "@/components/InicjatywyGlowna.vue";

export default {
    data() {
        return {
            inicjatywy: [],
            inicjatywyArchiwum: [],
        };
    },
    components: {
        InicjatywyGlowna: InicjatywyGlowna,
    },
    name: "Lista-inicjatyw-Mainpage",
    computed: {
        inicjatywyGlowne() {
            const initiativeList = this.inicjatywy;
            return initiativeList;
        },
    }, 
    methods: {
        getInicjatywy() {
            // axios.get("/api/fullSection/fullSection.php").then((response) => {
            axios.get("https://rzeszowska.org.pl/api/inicjatywy/top.php").then((response) => {
            // axios.get("http://localhost:3000/inicjatywy").then((response) => {
                this.inicjatywy = response.data;
            });
        },
    },
    mounted() {
        this.getInicjatywy();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.activity {
    &__header {
        h2 {
            padding-bottom: 40px;
        }
    }
}
</style>
