<template>
    <div v-if="newsList.length">
        <section class="full news">
            <div class="container">
                <div class="news__header">
                    <header class="medium">
                        <span class="subheader">Co słychać?</span>
                        <h2>aktualności</h2>
                    </header>
                </div>
                <div class="news-container flex3">
                    <article v-for="(item, index) in paginatedNewsList" :key="index" class="fade fadeinbottom fadeinbottomstart">
                        <div class="photo">
                            <picture><img :src="item.imagePath" alt=""></picture>
                        </div>
                        <div class="text">
                            <header class="normal">
                                <h2>{{ item.title }}</h2>
                            </header>
                            <div v-html="item.shortcut"></div>
                            <router-link class="goTo" :to="item.link" :title="item.title">Zobacz więcej</router-link>
                        </div>
                    </article>
                </div>
            </div>
            <a class="news__all showAll" aria-label="Przejdź do strony z aktualnościami" href="/aktualnosci/">Zobacz wszystkie</a>
        </section>
    </div>
</template>

<script>
import axios from "axios";
import Wpis from "@/pages/Wpis.vue";

export default {
    data() {
        return {
            newsList: [],
            news: "news",
            currentPage: 1,
            iloscElementowNaJednejStronie: 9,
            // pageOfItems: 6,
            allNewsList: this.newsList,
            newsCategories: null
        };
    },
    computed: {
        paginatedNewsList() {
            const startNewsIndex = (this.currentPage - 1) * this.iloscElementowNaJednejStronie;
            const endNewsIndex = this.currentPage * this.iloscElementowNaJednejStronie;
            return this.newsList.slice(startNewsIndex, endNewsIndex);
        },
    },
    name: "Lista-aktualnosci-Mainpage",
    methods: {
        addNewsItemsRoutes() {
            this.newsList.forEach((x) => {
                // const alreadyExistingRoute = this.$router.matcher.getRoutes().find((route) => x.link.includes(route.path));

                // if (!alreadyExistingRoute) {
                    this.$router.addRoute({
                        path: x.link,
                        name: x.title,
                        gallery: x.gallery,
                        component: Wpis,
                        props: { pageData: x },
                        meta: {
                            title: x.title,
                        },
                    });
                // }
            });
        },
        getNewsList() {
            // axios.get("/api/newsList/newsList.php").then((response) => {
            axios.get("https://rzeszowska.org.pl/api/newsList/top.php").then((response) => {
            // axios.get("http://localhost:3000/newsList").then((response) => {
                this.newsList = response.data;
                this.addNewsItemsRoutes();
            });
        },
        // getNewsCategories() {
        //     // axios.get("/api/newsList/newsList.php").then((response) => {
        //         // axios.get("https://rzeszowska.org.pl/api/newsList/newsList.php").then((response) => {
        //         axios.get("http://localhost:3000/newsCategories").then((response) => {
        //         this.newsCategories = response.data;
        //     });
        // },
        clickCallback(currentPage) {
            this.currentPage = currentPage;
        },
    },
    mounted() {
        this.getNewsList();
        // this.getNewsCategories();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";


.showAll {
    color: $colorDark;
    margin: 0 auto;
    padding: 20px;
    font-weight: 700;
    border: 1px solid $colorDark;
    text-align: center;
    width: fit-content;
    position: relative;
    transition: 0.3s all ease-in;
    display: block;
    margin-top: 30px;
    z-index: 1;
    @include aEffect;
    background-color: $colorWhite;
    &::before {
        background: darken($colorDark, 0);
    }
    &:hover {
        color: $colorWhite;
    }
}
</style>
