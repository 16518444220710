<template>
    <div>
        <section class="full dark">
            <div class="container fade fadein fadeinstart">
                <header>
                    <h1>POZNAJ NAS</h1>
                </header>
                <p>
                    Sięgając do korzeni tworzymy współczesną kulturę. Chcemy
                    podawać kolejne powody do dumy z bycia mieszkańcem
                    Podkarpacia lub bycia osobą, w jakiś sposób związaną z
                    naszym regionem. Jesteśmy regionalnymi pasjonatami. Łączy
                    nas pragnienie odkrywania historii i dziedzictwa
                    regionalnego, chęć współtworzenia kultury i wpływania na
                    rozwój tożsamości kulturowej. Wierzymy, że działając
                    wspólnie możemy zmienić świat na lepszy, na taki, w którym
                    ludzie sobie ufają, współpracują i pomagają sobie nawzajem,
                    potrafią dzielić się z innymi i wspólnie rozwiązywać
                    problemy. Staramy się tworzyć mosty współpracy z
                    regionalnymi aktywistami oraz partnerstwa z organizacjami,
                    publiczną administracją i biznesem.
                </p>
                <span class="subheader"
                    >Poznaj przedstawicieli zarządu Fundacji Rzeszowskiej i
                    przedsiębiorstwa społecznego Spółka Rzeszowska sp. z o. o.
                    non profit.</span
                >
            </div>
        </section>
        <section class="events people" v-if="pageData">
            <div class="container">
                <div class="row row30 management">
                        <div class="col col9">
                            <header>
                                <h3>Zarząd 
                                    Fundacji Rzeszowskiej</h3>
                            </header>
                        </div>
                        <div class="col col3">
                            <header>
                                <h3>Zarząd spółki rzeszowskiej 
                                    sp. z o.o.</h3>
                            </header>
                        </div>
                    </div>
                <div class="row row30">
                    <div
                        class="col col3 event-people"
                        v-for="(zarzad, index) in zarzad"
                        :key="index"
                    >
                        <Person :person="zarzad" />
                    </div>
                </div>
            </div>
        </section>
        <section class="full dark">
            <div class="container fade fadein fadeinstart">
                <header class="">
                    <h2>KOLEKTYW FUNDACJI RZESZOWSKIEJ</h2>
                </header>
                <p>
                    Nasze inicjatywy społeczno-kulturalne powstają w wyniku
                    nieustającej burzy mózgów, która trwa i się rozrasta odkąd
                    powołaliśmy Fundację Rzeszowską do życia. Pojawienie się
                    nowych aktywistów w naszym otoczeniu często skutkuje
                    realizacją nowych, interdyscyplinarnych inicjatyw opartych o
                    inspiracje regionalnym dziedzictwem kulturowym. Pracujemy w
                    zespołach projektowych w zależności od charakteru
                    podejmowanych przez nas inicjatyw.
                </p>
                <span class="subheader"
                    >Poznaj nas i ludzi, z którymi współpracujemy i polecamy do
                    współpracy.</span
                >
            </div>
        </section>

        <section class="events people" v-if="pageData">
            <div class="container">
                <div class="row row30">
                    <div
                        class="col col3 event-people"
                        v-for="(kolektyw, index) in kolektyw"
                        :key="index"
                    >
                        <Person :person="kolektyw" />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from "axios";
import Person from "@/components/Person";
// import Full from "@/components/Full";

export default {
    data() {
        return {
            peopleSection: [],
        };
    },
    components: {
        // Full: Full,
        Person: Person,
    },
    name: "Ludzie-strona",
    props: ["pageData", "person"],
    computed: {
        zarzad() {
            const heading = this.peopleSection[0];
            return heading;
        },
        kolektyw() {
            const heading = this.peopleSection[1];
            return heading;
        },
    },
    methods: {
        getPeopleSection() {
            // axios.get("/api/fullSection/fullSection.php").then((response) => {
            axios
                .get("https://rzeszowska.org.pl/api/people/people.php")
                .then((response) => {
                    // axios.get("http://localhost:3000/peopleSection").then((response) => {
                    this.peopleSection = response.data;
                });
        },
    },
    mounted() {
        this.getPeopleSection();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.dark {
    h1 {
        color: $colorWhite;
        @include fontSize30px;
        font-weight: 400;
    }
}
.event-people.col3 {
    @media screen and (max-width: $tablet) {
        width: 50%;
    }
    @media screen and (max-width: $mobile) {
        width: 100%;
    }
}
.people {
    @include padding50;
}
</style>
