<template>
    <div v-if="videoItems" v-html="videoItems" class="video">
    </div>
</template>

<script>
import "magnific-popup";
import $ from "jquery";

export default {
    name: "Video-modul",
    props: ["videoItems"],
    methods: {
        esMagnifique() {
            $(document).ready(function () {
                $(".galleryPopupSingle").magnificPopup({
                    delegate: "a",
                    type: "image",
                    gallery: {
                        enabled: !0,
                    },
                });
                $(".galleryPopup").magnificPopup({
                    delegate: "a",
                    type: "image",
                    gallery: {
                        enabled: !0,
                    },
                });
                $(".videoPopup").magnificPopup({
                    delegate: "a",
                    type: "iframe",
                    gallery: {
                        enabled: !0,
                    },
                });
            });
        },
    },
    mounted() {
        this.esMagnifique();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.video {
    position: relative;
    iframe {
        width: 100%;
    }
}

</style>
