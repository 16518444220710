<template>
    <div>
        <aside class="menu-top">
            <div class="container menu-top-container">
                <div class="left-top">
                    <div class="tel">
                        <a href="tel:737900850">+48 737900850</a>
                    </div>
                </div>
                <div class="right-top">
                    <div class="social">
                        <ul>
                            <li>
                                <a
                                    rel="noopener noreferrer nofollow"
                                    target="_blank"
                                    href="https://www.instagram.com/fundacja_rzeszowska/"
                                    title="Nasz profil na Instagramie"
                                ></a>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer nofollow"
                                    target="_blank"
                                    href="https://www.facebook.com/fundacjarzeszowska"
                                    title="Nasz profil na Facebooku"
                                ></a>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer nofollow"
                                    target="_blank"
                                    href="https://www.youtube.com/channel/UCaqLxGhvBLIb3LBlYdanslw"
                                    title="Nasz profil na Youtube"
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <button
                    class="burgermenu"
                    type="button"
                    title="Kliknij, by otworzyć menu"
                ></button>
            </div>
        </aside>
        <header class="topbar">
            <div class="container topbar-container">
                <h2>
                    <router-link to="/"
                        ><picture
                            ><img
                                src="@/assets/img/svg/main-logo.svg"
                                alt="Fundacja Rzeszowska"
                                title="Fundacja Rzeszowska" /></picture
                    ></router-link>
                </h2>
                <!-- v-on:click="showSubmenu" -->
                <nav class="navbar zMenu">
                    <!-- ref="menu" -->
                    <ul>
                        <li v-for="item in menu" :key="item.name" :class="{ 'subMenu' : item.galaz && item.rozwin == 1 }">
        
                            <a :href="`${item.href}`" v-if="item.href.startsWith('/http') || item.href.startsWith('http')">{{ item.name }}</a>
                            <!-- <a :href="`${item.href}`" v-if="item.href.startsWith('/http') || item.href.startsWith('http')">{{ item.name }}</a> -->
                            <router-link v-else-if="currentUrlOrigin && item.href != ''" :to="`${item.href}`">{{ item.name }}</router-link>
                            <router-link v-else :to="``" :class="{ 'noclick' : item.noclick == 1 }">{{ item.name }}</router-link>

                            <button v-if="item.galaz && item.rozwin == 1" class="showButton" data-wcag="1" aria-label="Rozwiń"><span style="display: none;">Rozwiń</span></button>
                            <ul v-if="item.galaz && item.rozwin == 1">
                                <li v-for="itemSub in item.galaz" :key="itemSub.name">
                                    <a :href="`${itemSub.link}`" v-if="itemSub.link.startsWith('http')">{{ itemSub.strona_nazwa_pl }}</a>
                                    <router-link v-else-if="itemSub.link != ''" :to="`/${itemSub.link}`">{{ itemSub.strona_nazwa_pl }}</router-link>
                                    <router-link v-else :to="``" :class="{ 'noclick' : itemSub.noclick == 1 }">{{ itemSub.strona_nazwa_pl }}</router-link>
                                    <!-- <a :href="`/${itemSub.link}`">{{ itemSub.strona_nazwa_pl }}</a> -->
                                    <!-- <router-link v-else :to="`/${itemSub.href}/`">{{ itemSub.name }}</router-link> -->
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    </div>
</template>

<script>
import axios from "axios";
import zMenu from "@/assets/js/zdzislowicz-burger-1.8";

export default {
    data() {
        return {
            menu: [],
            currentUrlOrigin: null
        };
    },
    name: "TopBar",

    methods: {
        getMenu() {
            axios
                .get("https://rzeszowska.org.pl/api/menu/menu.php")
                .then((response) => {
                    this.menu = response.data;
                });
        },
    },
    computed: {
        isExternal() {
            // return this.startsWith(this.to, 'http') ? 'a' : 'router-link';
            return typeof this.to === "string";
        }
    },
    mounted() {
        this.getMenu();

this.currentUrlOrigin = window.location.origin;
    },
    updated() {
        zMenu.zMenuRun();
    }    
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
// @import "@/assets/css/mixins.scss";

.menu-top {
    height: 55px;
    background: $colorBlack;
    .menu-top-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        .left-top {
            display: flex;
            flex-direction: row;
            align-items: center;

            div {
                position: relative;
                picture {
                    img {
                        height: 24px;
                        width: auto;
                        @media screen and (max-width: $mobile) {
                            height: 20px;
                        }
                    }
                }
            }
            a {
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 9;
                color: $colorWhite;
            }

        }
        .right-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            @media screen and (max-width: $tablet) {
                .contact {
                    display: none !important;
                }
                .social {
                    display: none !important;
                }
            }
            @media screen and (max-width: $smalltablet) {
                display: none;
            }
            .contact {
                margin: 0 0 0 24px;
                @include fontSize15px;
                a {
                    transition: color 0.3s;
                    color: $colorWhite;
                }
                .active {
                    color: $colorBlue;
                }
            }
        }
    }
    .burgermenu {
        @include button;
        background: url(../assets/img/svg/hamburger-20x20.svg) center center
            no-repeat;
        background-size: 32px auto;
        width: 44px;
        height: 44px;
        right: -7px;
        position: relative;

        @media screen and (min-width: 1023px) {
            display: none;
        }
    }
}

.topbar {
    color: $colorWhite;
    position: relative;
    z-index: 4;
    margin: 0 auto;
    height: auto;
    background-color: $colorDark;
    &-container {
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 1023px) {
            height: 60px;
        }
    }
    h2 {
        position: relative;
        padding: 0;

        picture {
            img {
                width: auto;
                height: 46px;
                @media screen and (max-width: $smalltablet) {
                    height: 40px;
                }
            }
        }
    }

    .burgermenu {
        @include button;
        background: url(../assets/img/svg/hamburger-20x20.svg) center center
            no-repeat;
        background-size: 32px auto;
        width: 32px;
        height: 32px;
        // margin-left: 20px;

        @media screen and (min-width: 1023px) {
            display: none;
        }
    }

    .navbar {
        z-index: 99;
        position: relative;
        width: 100%;
        padding-left: 100px;
        ul {
            width: 100%;
            display: flex;
            z-index: 2;
            align-items: center;
            justify-content: flex-end;
            position: relative;
        }
        & > ul {
            gap: 38px;
            height: 80px;
        }
        .showsub {
            display: flex;
        }
        li {
            height: 100%;
            display: flex;
            z-index: 2;
            position: relative;
            align-items: center;
            a {
                padding: 0;
                font-weight: 400;
                @include fontSize16px;
                display: flex;
                align-items: center;
                position: relative;
                transition: 0.3s ease all;
            }
            & > a {
                height: 100%;
                display: flex;
                align-items: center;
            }
            &:last-of-type {
                a {
                    margin-right: 0;
                }
            }
            button {
                height: 80px;
                width: 22px;
                padding: 0;
                background: url(../assets/img/svg/icon-arrow-bottom.svg) right
                    center no-repeat transparent;
                background-size: 70% auto;
                cursor: pointer;
                border: none;
            }

            ul {
                top: 80px;
                left: -30px;
                width: fit-content;
                min-width: 114px;
                padding: 25px;
                z-index: 5;
                align-items: flex-start;
                flex-direction: column;
                position: absolute;
                display: none;
                z-index: 99;
                li {
                    margin-right: 0;
                    white-space: nowrap;
                    display: flex;
                    z-index: 5;
                    opacity: 1;
                    &:hover {
                        text-decoration: none;
                    }
                    button {
                        height: 30px;
                        width: 30px;
                        background: url(../assets/img/svg/icon-arrow-bottom.svg)
                            right center no-repeat;
                        background-size: 70% auto;
                        display: inline;
                    }
                    a {
                        line-height: 30px;
                        width: 100%;
                        text-transform: initial;
                        display: block;
                    }
                    ul {
                        // position: relative;
                        position: absolute;
                        left: 100%;
                        padding: 25px 30px;
                        top: 0;
                        z-index: 99;
                        &.showButton {
                            position: absolute;
                        }
                    }
                }
            }
        }

        //NAVBAR-COLORS & SIZES
        li a {
            color: $colorWhite;
        }
        // li .active {
        // color: $green;
        // text-decoration: underline;
        // }
        li ul {
            background: $colorGray;
        }
        ul > li.active > a {
            color: $colorYellow;
        }
        // ul > li.active > button {
        //     color: black;
        // }
    }
}
.noclick {
    cursor: default;
}

</style>
