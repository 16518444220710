<template>
    <article v-if="daneDoWyswietlenia">
        <div class="halfWide__left halfWide__left--img">
            <picture :style="{ backgroundImage: fullSectionImagePath }"><img :src="daneDoWyswietlenia.imagePath" alt="" /> </picture>
        </div>
        <div class="halfWide__right halfWide__right--text" :class="bright">
            <div
                class="fade fadein fadeinstart"
            >
                <!-- :class="{
                    fadeinright: daneDoWyswietlenia % 2 === 0,
                    fadeinleft: daneDoWyswietlenia % 2 !== 0,
                }" -->
                <header class="medium">
                    <span class="subheader">{{ daneDoWyswietlenia.subheader }}</span>
                    <h2>{{ daneDoWyswietlenia.header }}</h2>
                </header>
                <div v-html="daneDoWyswietlenia.content"></div>
            </div>
        </div>
    </article>
</template>

<script>
// import axios from "axios";
export default {
    data() {
        return {
            sectionAbout: [],
            bright: "",
        };
    },
    name: "DoubleHalfDividerWide",
    props: ["offer", "daneDoWyswietlenia"],
    computed: {
        fullSectionImagePath() {
            let valToReturn = "none";
            if (this.daneDoWyswietlenia && this.daneDoWyswietlenia.imagePath) {
                valToReturn = `url(${this.daneDoWyswietlenia.imagePath})`;
            }
            return valToReturn;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.halfWide {
    article {
        display: flex;
        position: relative;
        height: 100%;
        width: 100%;
        min-height: 600px;
        &:nth-of-type(2n + 2) {
            flex-direction: row-reverse;
        }
        @media screen and (max-width: $smalltablet) {
            min-height: auto;   
        }
        &.bright {
            .halfWide__right {
                background: $colorWhiteF7;
                text-align: left;
                header {
                    h2,
                    h3 {
                        color: $colorDark;
                        text-transform: uppercase;
                    }
                }
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }
                p {
                    color: $colorDark;
                }
                a {
                    display: block;
                    @include fontSize22px;
                }
            }
        }
        &.noheader {
            .medium {
                h2 {
                    display: none;
                }
            }
        }
        &.darkbg {
            span, .subheader {
                color: $colorWhite;
            }
        }
    }

    header {
        h2,
        h3 {
            color: $colorWhite;
        }
    }
    &__left {
        width: 50%;
        height: auto;
        position: relative;
        picture {
            background-size: cover;
            background-position: 50%;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            position: relative;
            img {
                width: 100%;
                height: auto;
                @media screen and (min-width: $tablet) {
                    display: none;
                }
            }
        }
    }
    &__right {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        width: 50%;
        height: auto;
        padding: 6%;
        background: $colorGrey2B;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p {
            color: $colorWhite;
        }
    }
    @media screen and (max-width: $tablet) {
        article {
            flex-direction: column;
            &:nth-of-type(2n + 2) {
                flex-direction: column;
            }
        }
        &__right {
            width: 100%;
        }
        &__left {
            width: 100%;
        }
    }
}
</style>
