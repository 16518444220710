<template>
    <form
        class="fixed__form"
        style="display: flex"
        method="post"
        action="/wyszukiwarka/"
    >
        <fieldset class="fixed__fieldset">
            <legend>Wyszukaj aktualności</legend>
            <label for="search">Wyszukaj</label>
            <input
                type="search"
                class="aside__search"
                id="search"
                v-model="fraza"
                placeholder="Znajdź aktualności"
                name="fraza"
            />
            <input
                type="submit"
                class="aside__submit"
                value="Szukaj"
                @click="search"
            />
        </fieldset>
    </form>
</template>
<script>

export default {
    data() {
        return {
            fraza: "",
            newsList: [],
        };
    },
    name: "Wyszukiwarka-modul",
    props: ["pageData"],
    methods: {
        search: function (e) {
            e.preventDefault();
            this.$router.push({ path: `/wyniki?q=${this.fraza}`});
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";
</style>
