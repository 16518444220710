<template>
    <section class="textContent" v-if="pageData">
        <header
            class="subpage-header subpage-header--small"
            :style="{ backgroundImage: fullSectionImagePath }"
        >
            <h1 class="content__header fade fadein fadeinstart">
                {{ pageData.title }}
            </h1>
        </header>
        <div class="container">
            <div class="row row30">
                <div class="col col8 news__articles">
                    <div v-html="pageData.content" class="text"></div>
                </div>
                <div class="col col4 news__right-side news__gallery">
                    <picture
                        v-if="
                            pageData.gallery.items &&
                            pageData.gallery.type == 'pozioma'
                        "
                        ><img :src="pageData.imagePath"
                    /></picture>
                    <div
                        v-if="
                            pageData.gallery.items &&
                            pageData.gallery.type == 'pionowa'
                        "
                    >
                        <Gallery :galleryItems="zmodyfikowaneDaneDoGalerii" />
                    </div>
                </div>
                <div v-if="pageData.aktualnosci.length > 0">
                    <div class="col col12">
                        <header class="initiativeSubheader newsHeader">
                            <h2>Aktualności w projekcie</h2>
                        </header>
                    </div>
                    <InicjatywaNaglowekSekcji
                        :title="pageData.aktualnosci.title"
                    ></InicjatywaNaglowekSekcji>
                    <InicjatywaListaAktualnosci
                        :initiativeNewsItems="computedAktualnosciInicjatywy"
                    ></InicjatywaListaAktualnosci>
                </div>
                <div v-if="pageData.people" class="col col12">
                    <header class="initiativeSubheader">
                        <h2>Twórcy</h2>
                    </header>
                    <!-- <InicjatywaNaglowekSekcji :title="pageData.people"></InicjatywaNaglowekSekcji> -->
                    <InicjatywaTworcy
                        :person="pageData.people"
                    ></InicjatywaTworcy>
                </div>
            </div>
            <div
                class="row row0"
                v-if="
                    pageData.gallery.items && pageData.gallery.type == 'pozioma'
                "
            >
                <!-- <div class="col col12">
                        <header class="initiativeSubheader"><h2>Fotorelacja</h2></header>
                    </div> -->
                <InicjatywaNaglowekSekcji
                    :title="pageData.gallery.title"
                ></InicjatywaNaglowekSekcji>
                <Gallery :galleryItems="zmodyfikowaneDaneDoGalerii" />
            </div>
        </div>
    </section>
</template>

<script>
import Gallery from "@/components/Gallery";
import InicjatywaTworcy from "@/components/inicjatywy/InicjatywaTworcy";
import InicjatywaListaAktualnosci from "@/components/inicjatywy/InicjatywaListaAktualnosci";
import InicjatywaNaglowekSekcji from "@/components/inicjatywy/InicjatywaNaglowekSekcji";

export default {
    data() {
        return {
            initiativeItem: null,
            initiativeArticles: "initiativeArticles",
            initiativeNewsList: null,
        };
    },
    name: "Wpis-inicjatywa",
    props: ["pageData"],
    components: {
        Gallery: Gallery,
        InicjatywaTworcy: InicjatywaTworcy,
        InicjatywaListaAktualnosci: InicjatywaListaAktualnosci,
        InicjatywaNaglowekSekcji: InicjatywaNaglowekSekcji,
    },
    computed: {
        fullSectionImagePath() {
            let valToReturn = "none";
            if (this.pageData && this.pageData.imagePath) {
                valToReturn = `url(${this.pageData.imagePath})`;
            }
            return valToReturn;
        },

        // computedTworcyInicjatywy() {
        //     const initiativeNewsData = this.pageData.people;
        //     return initiativeNewsData;
        // },

        computedAktualnosciInicjatywy() {
            const currentNewsList = this.pageData.aktualnosci;
            const newNewsList = [];
            currentNewsList.forEach((item) => {
                const newItem = {
                    imagePath: item.imagePath,
                    title: item.title,
                    shortcut: item.shortcut,
                    // content: item.content,
                    link: item.link,
                };
                newNewsList.push(newItem);
            });
            return newNewsList;
        },

        zmodyfikowaneDaneDoGalerii() {
            const currentGalleryData = this.pageData.gallery.items;
            const newGalleryData = [];
            currentGalleryData.forEach((item) => {
                const newItem = {
                    imagePath: `upload/pictures/${item.zdjecie_sciezka}.webp`,
                    imagePathMobile: `upload/pictures/mobile/${item.zdjecie_sciezka}.webp`,
                    imagePathNormal: `upload/pictures/normal/${item.zdjecie_sciezka}.webp`,
                    imageAlt: item.alt,
                    imageTitle: item.title,
                };
                newGalleryData.push(newItem);
            });
            return newGalleryData;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.subpage-header {
    &--small {
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        background-repeat: no-repeat;
        @media screen and (max-width: $smalltablet) {
            min-height: auto;
        }
    }
}

.news {
    &__right-side {
        background-color: transparent;
        &::after {
            content: "";
            background-color: transparent;
        }
    }
}

.initiativeSubheader {
    padding-top: 30px;
    h2 {
        @include fontSize28px;
        text-align: left;
    }
}
</style>
