<template>
    <section class="textContent">
        <div class="container">
            <header class="header">
                <h1 class="content__header fade fadein fadeinstart">Błąd 404</h1>
            </header>
            <p>Strony nie znaleziono</p>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    name: 'NotFound',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.textContent {
    @include padding50;
}

</style>
