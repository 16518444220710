<template>
    <div class="col col12" v-if="title">
        <header class="initiativeSubheader"><h2>{{ title }}</h2></header>
    </div>
</template>

<script>

export default {
    data() {
        return {

        };
    },
    name: "Wpis-naglowek-header",
    props: ["title"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.subpage-header {
    &--small {
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        background-repeat: no-repeat;
        @media screen and (max-width: $smalltablet) {
            min-height: auto;
        }
    }
}

.news {
    &__right-side {
        background-color: transparent;
        &::after {
            content: "";
            background-color: transparent;
        }
    }
}

.initiativeSubheader {
    h2 {
        @include fontSize28px;
        text-align: left;
    }
}



</style>
