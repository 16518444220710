<template>
    <div v-if="listaNewsow">
        <article v-for="(item, index) in listaNewsow" :key="index">
            <div class="photo">
                <picture><img :src="item.imagePath" alt=""></picture>
            </div>
            <div class="text">
                <header class="normal">
                    <h2>{{ item.title }}</h2>
                </header>
                <p v-html="item.shortcut"></p>
                <div v-html="item.content"></div>
                <router-link class="goTo" :to="item.link" :title="item.title">Zobacz więcej</router-link>
            </div>
        </article>
    </div>
</template>

<script>

export default {
    data() {
        return {
        };
    },
    name: "Inicjatywa-news",
    props: ["listaNewsow"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";


.flex4 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    flex-wrap: wrap;
    article {
        width: calc((100% - 90px) / 4);
        position: relative;
        background: $colorGreyF9;
        @media screen and (max-width: $smalltablet) {
            width: calc((100% - 30px) / 2);
        }
        @media screen and (max-width: $mobile) {
            width: 100%;
        }
        .photo {
            // position: relative;
            overflow: hidden;
        }
        a {
            // position: absolute;
            width: 100%;
            height: 100%;
            &:hover picture {
                transform: scale(1.5) rotate(25deg);
            }
            picture {
                width: 100%;
                height: auto;
                position: relative;
                background-size: cover;
                transition: transform 0.3s ease-in-out;
                background-position-x: right;
                img {
                    width: 100%;
                    height: auto;
                    visibility: hidden;
                }
            }
        }
        .text {
            padding: 15px;
            display: flex;
            align-items: center;
            flex-direction: column;
            h3 {
                @include fontSize25px;
                text-transform: uppercase;
                font-weight: 300;
            }
            .goTo {
                margin: 0 auto;
                background: $colorDark;
                color: $colorWhite;
                padding: 20px;
                transition: 0.3s ease-in all;
                width: fit-content;
                &:hover {
                    color: $colorDark;
                    background-color: $colorYellow;
                }
            }
        }
    }
}
.showAll {
    color: $colorDark;
    margin: 0 auto;
    padding: 20px;
    font-weight: 700;
    border: 1px solid $colorDark;
    text-align: center;
    width: fit-content;
    position: relative;
    transition: 0.3s all ease-in;
    display: block;
    margin-top: 30px;
    z-index: 1;
    @include aEffect;
    background-color: $colorWhite;
    &::before {
        background: darken($colorDark, 0);
    }
    &:hover {
        color: $colorWhite;
    }
}
</style>
