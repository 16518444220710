/*
         _       _       _                  _                       _
 ____ __| | ____(_) ___ | |  ___ __      __(_)  ___  ____    _ __  | |
|_  // _` ||_  /| |/ __|| | / _ \\ \ /\ / /| | / __||_  /   | '_ \ | |
 / /| (_| | / / | |\__ \| || (_) |\ V  V / | || (__  / /  _ | |_) || |
/___|\__,_|/___||_||___/|_| \___/  \_/\_/  |_| \___|/___|(_)| .__/ |_|
                                                            |_|

COOKIES
www.zdzislowicz.pl
biuro@zdzislowicz.pl

all rights reserved
*/

$(document).ready(function()
{
    $('.cookies .close').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        setCookie('cookies', '1', 3600, '/', '', '');
        $('.cookies').fadeOut(300);
    });
});

// function getCookie(name)
// {
//     var a_all_cookies = document.cookie.split( ';' );
//     var a_temp_cookie = '';
//     var cookie_name = '';
//     var cookie_value = '';
//     var b_cookie_found = false;

//     for ( i = 0; i < a_all_cookies.length; i++ ) {
//         a_temp_cookie = a_all_cookies[i].split( '=' );
//         cookie_name = a_temp_cookie[0].replace(/^\s+|\s+$/g, '');
//         if ( cookie_name == name )
//         {
//             b_cookie_found = true;
//             if ( a_temp_cookie.length > 1 )
//                 cookie_value = unescape( a_temp_cookie[1].replace(/^\s+|\s+$/g, '') );
//             return cookie_value;
//             break;
//         }
//         a_temp_cookie = null;
//         cookie_name = '';
//     }
//     if ( !b_cookie_found )
//         return null;
// }

// function setCookie( name, value, expires, path, domain, secure )
// {
//     var today = new Date();
//     today.setTime( today.getTime() );
//     if ( expires )
//         expires = expires * 60 * 60 * 24;
//     var expires_date = new Date( today.getTime() + (expires) );
//     document.cookie = name + "=" +escape( value ) +
//     ( ( expires ) ? ";expires=" + expires_date.toGMTString() : "" ) +
//     ( ( path ) ? ";path=" + path : "" ) +
//     ( ( domain ) ? ";domain=" + domain : "" ) +
//     ( ( secure ) ? ";secure" : "" );
// }