var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pageData)?_c('section',{staticClass:"textContent"},[_c('header',{staticClass:"subpage-header subpage-header--small",style:({ backgroundImage: _vm.fullSectionImagePath })},[_c('h1',{staticClass:"content__header fade fadein fadeinstart"},[_vm._v(" "+_vm._s(_vm.pageData.title)+" ")])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row row30"},[_c('div',{staticClass:"col col8 news__articles"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.pageData.content)}})]),_c('div',{staticClass:"col col4 news__right-side news__gallery"},[(
                        _vm.pageData.gallery.items &&
                        _vm.pageData.gallery.type == 'pozioma'
                    )?_c('picture',[_c('img',{attrs:{"src":_vm.pageData.imagePath}})]):_vm._e(),(
                        _vm.pageData.gallery.items &&
                        _vm.pageData.gallery.type == 'pionowa'
                    )?_c('div',[_c('Gallery',{attrs:{"galleryItems":_vm.zmodyfikowaneDaneDoGalerii}})],1):_vm._e()]),(_vm.pageData.aktualnosci.length > 0)?_c('div',[_vm._m(0),_c('InicjatywaNaglowekSekcji',{attrs:{"title":_vm.pageData.aktualnosci.title}}),_c('InicjatywaListaAktualnosci',{attrs:{"initiativeNewsItems":_vm.computedAktualnosciInicjatywy}})],1):_vm._e(),(_vm.pageData.people)?_c('div',{staticClass:"col col12"},[_vm._m(1),_c('InicjatywaTworcy',{attrs:{"person":_vm.pageData.people}})],1):_vm._e()]),(
                _vm.pageData.gallery.items && _vm.pageData.gallery.type == 'pozioma'
            )?_c('div',{staticClass:"row row0"},[_c('InicjatywaNaglowekSekcji',{attrs:{"title":_vm.pageData.gallery.title}}),_c('Gallery',{attrs:{"galleryItems":_vm.zmodyfikowaneDaneDoGalerii}})],1):_vm._e()])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col col12"},[_c('header',{staticClass:"initiativeSubheader newsHeader"},[_c('h2',[_vm._v("Aktualności w projekcie")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"initiativeSubheader"},[_c('h2',[_vm._v("Twórcy")])])}]

export { render, staticRenderFns }