<template>
    <section v-view="viewHandler" class="full statistics col4">
        <div class="container statistics-container">
            <header class="medium fade fadein fadeinstart">
                <span class="subheader">kilka statystyk</span>
                <h2>nasze osiągnięcia</h2>
            </header>
            <div class="box info-box">
                <div class="info-box__quantity" style="position: relative">
                    <number v-if="jestemNaEkranie" ref="number1" :from="0" :to="30" :format="theFormat" :duration="5" :delay="0.3" easing="Power1.easeOut" />
                    <!-- <number animationPaused ref="number2" :to="10" :duration="5" easing="Back.easeIn" @complete="completed" @click="playAnimation" /> -->
                    <!-- <span class="counter counter1" data-start="0" data-stop="30" data-speed="1000">0</span><span class="counterHide" style="visibility: hidden; z-index: -1">30</span> -->
                </div>
                <div class="info-box__block">
                    <p>i więcej autorskich inicjatyw</p>
                    <span class="info-box__title">zrealizowanych z regionalnymi twórcami</span>
                </div>
            </div>
            <div class="box info-box">
                <div class="info-box__quantity" style="position: relative">
                    <!-- <span class="counter counter1" data-start="0" data-stop="65" data-speed="1000">0</span><span class="counterHide" style="visibility: hidden; z-index: -1">65</span> -->
                    <number v-if="jestemNaEkranie"  ref="number1" :from="0" :to="65" :format="theFormat" :duration="5" :delay="0.3" easing="Power1.easeOut" />
                    <!-- <number animationPaused ref="number2" :to="10" :duration="5" easing="Back.easeIn" @complete="completed" @click="playAnimation" /> -->
                </div>
                <div class="info-box__block">
                    <p>i więcej wydarzeń</p>
                    <span class="info-box__title">zorganizowanych z sukcesem</span>
                </div>
            </div>
            <div class="box info-box">
                <div class="info-box__quantity" style="position: relative">
                    <number v-if="jestemNaEkranie"  ref="number1" :from="0" :to="250000" :format="theFormat" :duration="5" :delay="0.3" easing="Power1.easeOut" />
                    <!-- <number animationPaused ref="number2" :to="250000" :duration="5" easing="Back.easeIn" @complete="completed" @click="playAnimation" /> -->
                    <!-- <span class="counter counter1" data-start="0" data-stop="250000" data-speed="1000">0</span><span class="counterHide" style="visibility: hidden; z-index: -1">250000</span> -->
                </div>
                <div class="info-box__block">
                    <p>i więcej odbiorców</p>
                    <span class="info-box__title">naszych działań kulturalnych</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// import axios from "axios";
import $ from "jquery";

export default {
    data() {
        return {
            newsList: [],
            news: "news",
            currentPage: 1,
            iloscElementowNaJednejStronie: 10,
            jestemNaEkranie: false,
            // pageOfItems: 6,
            // allNewsList: this.newsList,
            // newsCategories: null
        };
    },
    computed: {
        paginatedNewsList() {
            const startNewsIndex = (this.currentPage - 1) * this.iloscElementowNaJednejStronie;
            const endNewsIndex = this.currentPage * this.iloscElementowNaJednejStronie;
            return this.newsList.slice(startNewsIndex, endNewsIndex);
        },
    },
    name: "Osiagniecia-Mainpage",
    methods: {
        viewHandler(e) {
            if (e.percentInView > 0.2) {
                this.jestemNaEkranie = true;
            } 
        },
        // Sets the format of the number
        theFormat(number) {
            return number.toFixed();
        },
        playAnimation() {
            $(
                window.scroll(function () {
                    this.zCounterRun();
                })
            );
        },
        zCounterRun() {
            $(".statistics").each(function () {
                var a = $(this).offset().top;

                if ($(window).scrollTop() + ($(window).height() / 4) * 3.5 > a) $(this).zCounter();
                this.$refs.number2.play();
            });
        },

        clickCallback(currentPage) {
            this.currentPage = currentPage;
        },
    },
    mounted() {
        this.playAnimation();
        
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.statistics {
    background: url(../assets/img/20190921_Inauguracja-Kwadratu-Kultury_fot.-Joanna-Bród.jpg) top center no-repeat;
    background-attachment: fixed;
    background-color: #000;
    background-size: cover;
    &-container {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        @media screen and (max-width: $smalltablet) {
            flex-direction: column;
            .box {
                width: 100%;
            }
        }
    }
    header {
        text-align: center;
        width: 100%;
    }
    h2 {
        color: $colorWhite;
    }
    .info-box {
        &__quantity {
            span {
                color: $colorWhite;
                @include fontSize92px;
                font-size: 700;
            }
            .counter {
                padding: 6px 0 0 0;
                width: 100%;
                text-align: center;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
        &__block {
            text-align: center;
            p {
                color: $colorWhite;
                text-align: center;
                @include fontSize18px;
                text-transform: uppercase;
            }
            span {
                color: $colorWhite;
                @include fontSize16px;
                font-style: italic;
            }
        }
    }
}
</style>
