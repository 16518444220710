<template>
    <section v-if="daneDoWyswietlenia" class="full" :style="{ backgroundImage: fullSectionImagePath }">
        <div class="container fade fadeinbottom fadeinbottomstart">
            <header class="medium">
                <h2>{{ daneDoWyswietlenia.header }}</h2>
            </header>
            <p v-if="daneDoWyswietlenia.paragraph">{{ daneDoWyswietlenia.paragraph }}</p>
            <span class="subheader">{{ daneDoWyswietlenia.subheader }}</span>
            <router-link v-if="daneDoWyswietlenia.href && daneDoWyswietlenia.noclick == 0" :to="`${daneDoWyswietlenia.href}`" class="showAllBright">ZOBACZ WIĘCEJ</router-link>
            <a v-else-if="daneDoWyswietlenia.hrefOutside && daneDoWyswietlenia.noclick == 0" :href="daneDoWyswietlenia.hrefOutside" class="showAllBright">ZOBACZ WIĘCEJ</a>
            <!-- <a v-else :href="daneDoWyswietlenia.hrefOutside" class="showAllBright">ZOBACZ WIĘCEJ</a> -->
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {};
    },
    name: "Full-section",
    props: ["pageData", "daneDoWyswietlenia"],
    computed: {
        fullSectionImagePath() {
            let valToReturn = "none";
            if (this.daneDoWyswietlenia && this.daneDoWyswietlenia.imagePath) {
                valToReturn = `url(${this.daneDoWyswietlenia.imagePath})`;
            }
            return valToReturn;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.subheader {
    color: $colorWhite;
}

.full {
    @include padding125;
    background: $colorWhite;
    // background-size: cover;
    .container {
        text-align: center;
    }
    header {
        text-align: center;
    }
    .content {
        @include padding50;
    }
    .bigPhoto {
        margin: 0 auto;
    }
    &.white {
        span {
            color: $menu;
        }
    }
    &.slide {
        position: relative;
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba($color: #000000, $alpha: 0.3);
            z-index: 1;
        }
        .container {
            z-index: 10;
        }
        span {
            color: $colorWhite;
        }
    }
    &.pre {
        header {
            h2 {
                &:first-of-type {
                    padding-bottom: 4px;
                }
                &:last-of-type {
                    padding-bottom: 0;
                }
            }
        }
        .content {
            text-align: center;
        }
        .buttons {
            margin: 0 auto;
            @include margin130Bottom;
        }
    }
    &.statistics {
        .subheader {
            color: $colorWhite;
        }
        .box {
            padding-bottom: 0;
        }
    }
    &.darkbg {
        span, .subheader {
            color: $colorWhite;
        }
    }
    .box {
        .bottom {
            .link {
                &:hover {
                    background: $colorWhite;
                    color: $colorGray;
                }
            }
        }
    }
}
.headerBright {
    color: $colorWhite;
}

.subtitle {
    background: $colorWhite;
    @include padding50;
}

.slide {
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    &-container {
        text-align: center;
    }
    h2 {
        color: $colorWhite;
    }
}

.dark {
    @include padding125;
    background: $colorGray;
    .subheader {
        @include fontSize14px;
        color: $colorWhite;
        text-align: center;
    }
    h2 {
        color: $colorWhite;
    }
    p {
        color: $colorWhite;
        text-align: center;
    }
    span {
        text-align: center;
        color: $colorWhite;
        strong {
            color: $colorWhite;
        }
    }
}

.showAllBright {
    color: $colorWhite;
    margin: 0 auto;
    padding: 20px;
    font-weight: 700;
    border: 1px solid $colorWhite;
    text-align: center;
    width: fit-content;
    position: relative;
    transition: 0.3s all ease-in;
    display: block;
    margin-top: 30px;
    z-index: 1;
    @include aEffect;
}
</style>
