<template>
    <div v-if="newsList.length">
        <header
            class="subpage-header subpage-header--big"
            :style="{ backgroundImage: fullSectionImagePath }"
        >
            <h1 class="fade fadein fadeinstart">Aktualności</h1>
        </header>
        <section class="news">
            <div class="container" ref="newPage">
                <header>
                    <h2 class="hide">News</h2>
                </header>
                <div class="row row60">
                    <div class="col col8 news__articles">
                        <article class="fade fadeinleft fadeinleftstart"
                            v-for="(item, index) in paginatedNewsList"
                            :key="index"
                        >
                            <div class="article__picture">
                                <picture
                                    ><img :src="item.imagePath" alt=""
                                /></picture>
                            </div>
                            <div class="article__text">
                                <!-- <ul class="topic__categories">
                                    <li v-for="(item, index) in paginatedNewsList.category" :key="index">
                                        <router-link to="" class="topic__category">{{ item.category.title }}</router-link>
                                    </li>
                                </ul> -->
                                <div>
                                    <header class="normal">
                                        <h2>{{ item.title }}</h2>
                                    </header>
                                    <router-link
                                        class="more"
                                        :to="item.link"
                                        :title="item.title"
                                    >
                                    </router-link>
                                    <p>{{ item.shortcut }}</p>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div class="col col4 news__right-side fade fadeinright fadeinrightstart">
                        <WyszukiwarkaModul></WyszukiwarkaModul>
                        <div class="categories">
                            <header><h3>Kategorie wpisów</h3></header>
                            <KategorieAktualnosci></KategorieAktualnosci>
                        </div>
                    </div>
                    <paginate
                        v-if="newsList.length > iloscElementowNaJednejStronie"
                        :page-count="
                            Math.ceil(newsList.length / iloscElementowNaJednejStronie)
                        "
                        :margin-pages="1"
                        :click-handler="clickCallback"
                        :prev-text="'Poprzednia'"
                        :next-text="'Następna'"
                        :container-class="'pagination'"
                        :page-class="'page-item'"
                    ></paginate>
                </div>
            </div>
        </section>
        <!-- :page-range="pageOfItems" -->
        <!-- <paginate
            v-if="newsList.length > iloscElementowNaJednejStronie"
            :page-count="
                Math.ceil(newsList.length / iloscElementowNaJednejStronie)
            "
            :margin-pages="1"
            :click-handler="clickCallback"
            :prev-text="'Poprzednia'"
            :next-text="'Następna'"
            :container-class="'pagination'"
            :page-class="'page-item'"
        ></paginate> -->
    </div>
    <div v-else>
        <section class="news">
            <div class="container">
                <header>
                    <h2 class="hide">News</h2>
                </header>
                <div class="row row60">
                    <div class="col col8 news__articles">
                        <p>
                         Przykro nam. Nic nie znaleziono w tej kategorii.
                        </p>
                    </div>
                    <div class="col col4 news__right-side">
                        <!-- Gdyby wyszukiwarka była w tym komponencie aktywna, to można byłoby użyć eventu resultsFound -->
                        <!-- <WyszukiwarkaModul @resultsFound="resultsFoundHandler"></WyszukiwarkaModul> -->
                        <div class="categories">
                            <header><h3>Kategorie wpisów</h3></header>
                            <KategorieAktualnosci></KategorieAktualnosci>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from "axios";
import KategorieAktualnosci from "@/components/KategorieAktualnosci.vue";
import WyszukiwarkaModul from "@/components/WyszukiwarkaModul.vue";

export default {
    components: {
        KategorieAktualnosci: KategorieAktualnosci,
        WyszukiwarkaModul: WyszukiwarkaModul,
    },
    data() {
        return {
            newsList: [],
            news: "news",
            currentPage: 1,
            iloscElementowNaJednejStronie: 4,
            // pageOfItems: 6,
            allNewsList: this.newsList,
            newsCategories: null,
            categoriesInNews: null,
        };
    },
    computed: {
        fullSectionImagePath() {
            let valToReturn = "none";
            if (this.daneDoWyswietlenia && this.daneDoWyswietlenia.imagePath) {
                valToReturn = `url(${this.daneDoWyswietlenia.imagePath})`;
            }
            return valToReturn;
        },
        paginatedNewsList() {
            const startNewsIndex =
                (this.currentPage - 1) * this.iloscElementowNaJednejStronie;
            const endNewsIndex =
                this.currentPage * this.iloscElementowNaJednejStronie;
            return this.newsList.slice(startNewsIndex, endNewsIndex);
        },
    },
    watch: {
        "pageData.id": function () {
            this.getNewsList();
        },
    },
    name: "Aktualnosci-lista",
    props: ["pageData"],
    methods: {
        getNewsList() {
            axios
                .get(
                    "https://rzeszowska.org.pl/api/newsList/newsList.php?id=" +
                        this.pageData.id
                )
                .then((response) => {
                    // axios.get("http://localhost:3000/newsList").then((response) => {
                    this.newsList = response.data;
                });
        },
        clickCallback(currentPage) {
            this.currentPage = currentPage;
            this.goto("newPage");
        },
        goto(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;

            window.scrollTo(0, top);
        },
        
    },
    mounted() {
        this.getNewsList();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

@mixin margin60 {
    margin-top: 60px;
    margin-bottom: 60px;
    @media screen and (max-width: $tablet) {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    @media screen and (max-width: $smalltablet) {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.subpage-header {
    background: $colorDark;
    text-align: center;
    &--big {
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-position: bottom;
        background-attachment: fixed;
        background-repeat: no-repeat;
        @media screen and (max-width: $smalltablet) {
            min-height: auto;
        }
    }
    h1 {
        padding: 50px 15px;
        color: $colorWhite;
        @include fontSize45px;
    }
}

.news {
    @include padding80;
    &__articles {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 30px;
        article {
            display: flex;
            align-items: flex-start;
            gap: 15px;
            position: relative;
            .article__picture {
                width: 43%;
            }
            ul {
                list-style: none;
                display: flex;
                gap: 5px;
                padding: 0;
                margin: 0;
                padding: 10px 0;
                li {
                    a {
                        color: $colorWhite;
                        height: 100%;
                        background: $colorMain;
                        padding: 10px;
                        transition: 0.3s ease-in all;
                        &:hover {
                            opacity: 0.7;
                        }
                    }
                }
            }
            .article__text {
                height: 100%;
                position: relative;
                width: 57%;
                gap: 15px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                h2 {
                    text-align: left;
                    @include fontSize20px;
                }
                div {
                    position: relative;
                    p {
                        text-align: left;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        padding: 0;
                        @media screen and (max-width: $smalltablet) {
                            -webkit-line-clamp: 2;
                        }
                        @media screen and (max-width: $mobile) {
                            display: none;
                        }
                    }
                }
            }
            .more {
                text-transform: uppercase;
                font-weight: 700;
                transition: 0.3s ease-in all;
                @include fontSize18px;
                padding-right: 20px;
                transition: 0.3s ease-in all;
                color: $colorGrey2B;
                width: 100%;
                height: 100%;
                top: 0;
                position: absolute;
                // &:before {
                //     content: "";
                //     position: absolute;
                //     bottom: 0;
                //     background: url(../assets/img/svg/icon-arrow-right.svg) right bottom 6px no-repeat;
                //     background-size: 8px;
                //     left: 0;
                //     width: 100%;
                //     height: 20px;
                // }
                // width: 100%;
                // height: 100%;
                // top: 0;
                // left: 0;
                // display: flex;
                // align-items: flex-end;
                // justify-content: flex-end;
                &:hover {
                    color: $colorMain;
                    padding-right: 30px;
                }
            }
            @media screen and (max-width: $mobile) {
                flex-direction: column;
                .article__picture {
                    width: 100%;
                }
                .article__text {
                    width: 100%;
                }
                h2 {
                    padding: 0;
                }
                .more {
                    position: relative;
                }
            }
        }
    }
    &__right-side {
        background-color: $colorGreye4;
        display: flex;
        flex-direction: column;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        height: auto;
        position: relative;
        z-index: 1;
        &::after {
            content: "";
            position: absolute;
            width: 100vw;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $colorGreye4;
            z-index: -1;
        }
        .fixed__form {
            z-index: 2;
            legend {
                display: none;
            }
            fieldset {
                display: flex;
                flex-wrap: wrap;
                gap: 10px 0;
                label {
                    width: 100%;
                    @include fontSize20px;
                    font-weight: 500;
                }
                input[type="search"] {
                    width: calc(100% - 52px);
                    border: none;
                    padding: 16px 20px;
                    font-family: $fontLato;
                    font-size: 16px;
                }
                input[type="submit"] {
                    width: 52px;
                    background: url(../assets/img/svg/icon-magnifier.svg) center
                        center no-repeat $colorMain;
                    background-size: 26px auto;
                    padding: 16px 20px;
                    color: transparent;
                    font-size: 13px;
                    transition: 0.3s ease-in all;
                    &:hover {
                        background-color: $colorGray;
                    }
                }
            }
        }
        @media screen and (max-width: $tablet) {
            order: 3;
        }
    }
    @media screen and (max-width: $tablet) {
        padding-bottom: 0 !important;
    }
}
.pagination {
    padding: 0 !important;
    margin: 0;
    list-style: none;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5px;
    @include margin60;
    li:first-child,
    li:last-child {
        transition: 0.3s ease-in all;
        &:hover {
            opacity: 0.7;
        }
    }
    @media screen and (max-width: $tablet) {
        order: 2;
    }
    @media screen and (max-width: $smalltablet) {
        li:first-child,
        li:last-child {
            display: none;
        }
    }
    li {
        color: #333333;
    }
    .page-item {
        line-height: 30px;
        display: inline-block;
        font-size: 16px;
        a {
            font-size: 16px;
            color: $colorDark;
            -webkit-transition: 0.3s ease all;
            transition: 0.3s ease all;
            padding: 0;
            display: inline-block;
            line-height: 30px;
            width: 30px;
            height: 30px;
            text-align: center;
            &:hover {
                color: $colorWhite;
                background: $colorDark;
            }
        }
    }
    .active {
        background: $colorDark;
        a {
            color: $colorWhite;
        }
    }
}
</style>
