<template>
    <section class="full brands">
        <div class="container">
            <div class="brands__header">
                <header class="medium fade fadein fadeinstart">
                    <span class="subheader">studio multimedialne, sklep z pamiątkami, plenerowe miejsce kultury</span>
                    <h2>POZNAJ NASZE MARKI</h2>
                </header>
            </div>
        </div>
        <div class="container brands-container col4">
            <div class="box">
                <a href="https://www.lada.rzeszowska.org.pl/"><picture><img src="@/assets/img/lada_www_poznaj_nasze_marki-135x165.png" alt="Logotyp - Lada rzeszowska" /></picture>
                </a>
            </div>
            <div class="box">
                <a href="https://studio.rzeszowska.org.pl/"><picture><img src="@/assets/img/studio_www_poznaj_nasze_marki-196x165.png" alt="Logotyp - Studio rzeszowska" /></picture>
                </a>
            </div>
            <div class="box">
                <router-link to="/kwadrat"><picture><img src="@/assets/img/kwadrat_www_poznaj_nasze_marki-238x165.png" alt="Logotyp - Kwadrat Kultury" /></picture>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            brands: [],
        };
    },
    
    name: "Marki-lista",
    methods: {
        getBrands() {
            // axios.get("/api/newsList/newsList.php").then((response) => {
                // axios.get("https://rzeszowska.org.pl/api/newsList/newsList.php").then((response) => {
                axios.get("http://localhost:3000/brands").then((response) => {
                this.brands = response.data;
            });
        },
        
    },
    mounted() {
        this.getBrands();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.brands {
    background: $colorYellow;
    .subheader {
        @include fontSize14px;
        color: $colorWhite;
    }
    header {
        h2 {
            color: $colorWhite;
        }
    }
    &-container {
        display: flex;
        picture {
            width: auto;
            height: auto;
            position: relative;
            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                margin: 0 auto;
            }
        }
        .box {
            position: relative;
            padding-bottom: 0 !important;
            a {
                width: 100%;
                // position: absolute;
                top: 0;
                left: 0;
                transition: 0.3s ease-in all;
                &:hover {
                    background-color: rgba($color: $colorGrey3C, $alpha: 0.6);
                }
            }
            @media screen and (max-width: $smalltablet) {
                width: auto;
            }
        }
    }
}
</style>
