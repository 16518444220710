<template>
    <section class="halfWide offer" data-space="0">
        <DoubleHalfDividerWide :daneDoWyswietlenia="fundacja" class="darkbg"></DoubleHalfDividerWide>
        <DoubleHalfDividerWide :daneDoWyswietlenia="odkrywamy" class="bright noheader"></DoubleHalfDividerWide>
        <DoubleHalfDividerWide :daneDoWyswietlenia="misja" class="darkbg"></DoubleHalfDividerWide>
        <Full :daneDoWyswietlenia="dziki" class="darkbg"></Full>
        <DoubleHalfDividerWide :daneDoWyswietlenia="dzialania" class="bright"></DoubleHalfDividerWide>
    </section>
</template>
<script>
import axios from "axios";
import Full from "@/components/Full";
import DoubleHalfDividerWide from "@/components/DoubleHalfDividerWide";
// import Gallery from "@/components/Gallery";

export default {
    data() {
        return {
            // DoubleHalfDividerWide: null,
            sectionAbout: [],
        };
    },
    components: {
        DoubleHalfDividerWide: DoubleHalfDividerWide,
        Full: Full,
        // Gallery: Gallery,
    },
    name: "O-nas",
    props: ["pageData"],
    computed: {
        fundacja() {
            const content = this.sectionAbout[0];
            return content;
        },
        odkrywamy() {
            const content = this.sectionAbout[1];
            return content;
        },
        misja() {
            const content = this.sectionAbout[2];
            return content;
        },
        dziki() {
            const content = this.sectionAbout[3];
            return content;
        },
        dzialania() {
            const content = this.sectionAbout[4];
            return content;
        },
    },
    methods: {
        getSectionAbout() {
            axios.get("https://rzeszowska.org.pl/api/sectionAbout/sectionAbout.php").then((response) => {
                // axios.get("http://localhost:3000/sectionAbout").then((response) => {
                this.sectionAbout = response.data;
            });
        },
    },
    mounted() {
        this.getSectionAbout();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

</style>
