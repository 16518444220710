<template>
    <div class="row row30 events" v-if="person">
        <div class="col col3" v-for="(person, index) in person" :key="index">
            <Person :person="person"></Person>
        </div>
    </div>
</template>

<script>

import Person from "@/components/Person";

export default {
    data() {
        return {
        };
    },
    name: "Inicjatywa-tworcy",
    props: ['person'],
    components: {
        Person: Person,
    },
    
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

// .events {
    // &.spacer {
    //     @include padding100;
    // }
    // &.people {
    //     h3 {
    //         text-align: center;
    //         font-weight: 300;
    //         @include fontSize25px;
    //         white-space: pre-line;
    //         padding: 0;
    //     }
    // }
    // .archive {
    //     @include padding40Top;
    // }
    // h3 {
    //     @include fontSize25px;
    //     font-weight: 700;
    //     text-transform: uppercase;
    //     text-align: left;
    // }
    
    .event {
        position: relative;
        overflow: hidden;
        &:hover {
            .picture {
                .overlay {
                    opacity: 1;
                    top: 0;
                }
            }
            a > header {
                visibility: hidden;
            }
        }
        p {
            @include fontSize14px;
        }
        span {
            @include fontSize16px;
            font-style: italic;
            display: block;
        }
        .subheader {
            color: $colorGrey3C;
        }
        a {
            width: 100%;
            z-index: 9;
            &:hover {
                p {
                    top: 50%;
                }
            }
        }
        .picture {
            position: relative;
            overflow: hidden;
            margin-bottom: 15px;
            .overlay {
                content: "";
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-color: rgba($color: $colorGrey3C, $alpha: 0.6);
                width: 100%;
                height: 100%;
                padding: 15px;
                mix-blend-mode: hard-light;
                z-index: 99;
                transition: 0.3s ease;
                top: 80px;
                bottom: 0;
                opacity: 0;
                left: 0;
                header {
                    h2 {
                        color: $colorWhite;
                    }
                    span {
                        color: $colorWhite;
                    }
                }
            }
            picture {
                background-color: white;
                display: flex;
                justify-content: center;
                width: 100%;
                // img {
                //     max-height: 340px;
                //     max-width: 380px;
                // }
            }
        }
    }
// }
</style>
