<template>
    <div v-if="galleryItems" class="gallery galleryPopup">
        <ul>
            <li v-for="(item, index) in galleryItems" :key="index" class="fade fadein fadeinstart">
                <a :href="`/${item.imagePath}`" :title="item.imageTitle">
                    <!-- <picture>
                        <source media="(min-width: 1279px)" :srcset="`${item.pathNormal}`" />
                        <source media="(min-width: 1023px)" :srcset="`${item.pathBig}`" />
                        <source media="(min-width: 767px)" :srcset="`${item.pathMedium}`" />
                        <source media="(min-width: 479px)" :srcset="`${item.pathSmall}`" />
                        <img :src="`${item.pathNormal}`" :alt="item.zdjecie_alt" />
                    </picture> -->
                    <picture>
                        <source :srcset="`/${item.imagePathMobile}`" media="(max-width:479px)">
                        <img :src="`/${item.imagePathNormal}`" :alt="item.imageAlt">
                    </picture> 
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import "magnific-popup";
import $ from "jquery";

export default {
    name: "Gallery-modul",
    props: ["galleryItems"],
    methods: {
        esMagnifique() {
            $(document).ready(function () {
                $(".galleryPopupSingle").magnificPopup({
                    delegate: "a",
                    type: "image",
                    gallery: {
                        enabled: !0,
                    },
                });
                $(".galleryPopup").magnificPopup({
                    delegate: "a",
                    type: "image",
                    gallery: {
                        enabled: !0,
                    },
                });
                $(".videoPopup").magnificPopup({
                    delegate: "a",
                    type: "iframe",
                    gallery: {
                        enabled: !0,
                    },
                });
            });
        },
    },
    mounted() {
        this.esMagnifique();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.gallery {
    padding: 15px 0;
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        gap: 10px;
    }
}

.textContent {
    .container {
        .row0 > .gallery {
            ul {
                display: flex;
                margin: 0;
                padding: 0;
                list-style: none;
                gap: 10px;
                li {
                    width: calc((100% - 10px) / 6);
                    picture {
                        width: 100%;
                        height: auto;
                        max-height: 202px;
                        position: relative;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: auto;
                            margin: 0;
                        }
                    }
                    @media screen and (max-width: $smalltablet) {
                        width: calc((100% - 10px) / 4);
                    }
                    @media screen and (max-width: $mobile) {
                        width: calc((100% - 10px) / 2);
                    }
                }
            }
        }
    }
    .news__articles {
        & > .gallery {
            ul {
                width: 100%;
                display: flex;
                margin: 0;
                padding: 0;
                list-style: none;
                gap: 10px;
                flex-wrap: wrap;
                li {
                    width: calc((100% - 20px) / 3);
                    a {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                    picture {
                        width: 100%;
                        height: auto;
                        // max-height: 202px;
                        position: relative;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: auto;
                            margin: 0;
                        }
                    }
                    @media screen and (max-width: $mobile) {
                        width: calc((100% - 10px) / 2);
                    }
                }
            }
        }
    }
    .news__right-side {
        .gallery {
            ul {
                flex-direction: column;
                @media screen and (max-width: $tablet) {
                    flex-direction: row;
                    flex-wrap: wrap;
                }
                li {
                    width: calc((100% - 30px) / 4);
                    @media screen and (max-width: $smalltablet) {
                        width: calc((100% - 10px) / 2);
                    }
                }
            }
        }
        &.news__gallery {
            .gallery {
                ul {
                    flex-direction: row;
                    flex-wrap: wrap;
                    li {
                        width: calc(50% - 5px);
                    }
                }
            }
        }
    }
}

</style>
