<template>
    <div id="app">
        <TopBar></TopBar>
        <main class="main" id="main-section">
            <router-view />
        </main>
        <Footer></Footer>
    </div>
</template>

<script>
import axios from "axios";
import TopBar from "@/components/TopBar";
import Glowna from "@/pages/Glowna.vue";
import Footer from "@/components/Footer";
import Onas from "@/pages/Onas.vue";
import Ludzie from "@/pages/Ludzie.vue";
import Aktualnosci from "@/pages/Aktualnosci.vue";
import Wpis from "@/pages/Wpis.vue";
import Inicjatywy from "@/pages/Inicjatywy.vue";
import WpisInicjatywa from "@/pages/WpisInicjatywa.vue";
import Tekstowa from "@/pages/Tekstowa.vue";
import Kwadrat from "@/pages/Kwadrat.vue";
import Kontakt from "@/pages/Kontakt.vue";
import Wyniki from "@/pages/Wyniki.vue";
import NotFound from "@/pages/NotFound.vue";

//js
import "./assets/js/admin";
import "./assets/js/zdzislowicz-cookies-1.1";
import "./assets/js/zdzislowicz-burger-1.8";
import "./assets/js/zdzislowicz-contact-1.5";
import "./assets/js/zdzislowicz-slider-1.7.js";
import './assets/js/zdzislowicz-animations-1.2.js';

// css
import "./assets/css/magnific.min.css";
import "./assets/css/zdzislowicz-animations-1.1.min.css";

export default {
    name: "FundacjaRzeszowska",
    data() {
        return {
            
        };
    },
    components: {
        TopBar: TopBar,
        Footer: Footer,
    },
    props: ["pageData", "wszystkieStrony"],
    
    methods: {
        addTextPagesRoutes() {
            this.textPages.forEach((x) => {
                let komponent;

                if (x.szablon == "strony/strona-tekstowa") komponent = Tekstowa;
                if (x.szablon == "strony/glowna") komponent = Glowna;
                if (x.szablon == "strony/ludzie") komponent = Ludzie;
                if (x.szablon == "strony/aktualnosci") komponent = Aktualnosci;
                if (x.szablon == "strony/inicjatywy") komponent = Inicjatywy;
                if (x.szablon == "strony/inicjatywa") komponent = WpisInicjatywa;
                if (x.szablon == "strony/o-nas") komponent = Onas;
                if (x.szablon == "strony/kontakt") komponent = Kontakt;
                if (x.szablon == "strony/kwadrat") komponent = Kwadrat;
                if (x.szablon == "strony/wyszukiwarka") komponent = Wyniki;

                this.$router.addRoute({
                    path: x.link,
                    // name: x.title,
                    gallery: x.gallery,
                    component: komponent,
                    props: { pageData: x, wszystkieStrony: x },
                    meta: {
                        title: x.title,
                    },
                });
            });
            this.$router.addRoute({
                path: "*",
                component: NotFound,
                meta: {
                    title: "Strony nie znaleziono",
                },
            });
        },

        addNewsItemsRoutes() {
            this.newsList.forEach((x) => {
                // const alreadyExistingRoute = this.$router.matcher.getRoutes().find((route) => x.link.includes(route.path));

                // if (!alreadyExistingRoute) {
                this.$router.addRoute({
                    path: x.link,
                    // name: x.title,
                    gallery: x.gallery,
                    component: Wpis,
                    props: { pageData: x },
                    meta: {
                        title: x.title,
                    },
                });
                // }
            });
        },
        
        getTextPages() {
            axios.get("https://rzeszowska.org.pl/api/textPages/textPages.php").then((response) => {
                // axios.get("http://localhost:3000/textPages").then((response) => {
                // axios.get(`http://localhost:3000/textPages/${id}`).then((response) => {
                this.textPages = response.data;
                this.addTextPagesRoutes();
            });
        },
        getNewsList() {
            // axios.get("/api/newsList/newsList.php").then((response) => {
            axios.get("https://rzeszowska.org.pl/api/newsList/newsList.php").then((response) => {
                // axios.get("http://localhost:3000/newsList").then((response) => {
                this.newsList = response.data;
                this.addNewsItemsRoutes();
            });
        },
        
    },
    created() {
        this.getTextPages();
    },
    mounted() {
        this.getNewsList();
        
    },
};
</script>

<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

#app {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    display: grid;
    -ms-grid-rows: auto 1fr auto;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
}

.subheader {
    text-align: center;
    color: $colorSubheader;
    font-style: italic;
    font-weight: 400;
    padding-bottom: 0;
    @include fontSize25px;
}
// .container {
//     text-align: center;
// }

.subtitle {
    background: $colorWhite;
    @include padding50;
}

.big {
    h2 {
        @include fontSize45px;
    }
    .subheader {
        @include fontSize25px;
        display: block;
        padding: 10px 0;
    }
}
.medium {
    h2,
    h3 {
        @include fontSize25px;
        padding-bottom: 40px;
    }
    .subheader {
        @include fontSize14px;
        display: block;
        padding: 10px 0;
    }
}
.normal {
    h2,
    h3 {
        @include fontSize18px;
    }
    .subheader {
        @include fontSize13px;
        display: block;
        padding: 10px 0;
    }
}
.small {
    h2,
    h3 {
        @include fontSize20px;
        padding-bottom: 10px;
    }
    .subheader {
        @include fontSize13px;
        display: block;
        padding: 10px 0;
    }
}

.subpage-header {
    margin-bottom: 80px;
    h1 {
        max-width: 1200px;
        margin: 0 auto;
        line-height: 1.4;
    }
    @media screen and (max-width: $smalltablet) {
        margin-bottom: 20px;
    }
}

.bodynoscroll {
    overflow-y: hidden;
}

.bodyscroll {
    overflow-y: scroll;
}

picture {
    width: 100%;
    height: auto;
    position: relative;
    img {
        width: 100%;
        height: auto;
    }
}

body {
    font-family: $fontRaleway;
    color: $colorGray;
    line-height: 1.4;
    @include fontSize14px;
    font-weight: 400;
    background-color: $colorWhite;
    a {
        font-family: $fontRaleway;
        color: $colorGray;
        // @media screen and (max-width: $smalltablet) {
        //     font-size: 17px !important;
        // }
        // @media screen and (max-width: $mobile) {
        //     font-size: 14px !important;
        // }
    }
    p {
        font-weight: 400;
        color: $colorGray;
        // @media screen and (max-width: $smalltablet) {
        //     font-size: 17px !important;
        // }
        // @media screen and (max-width: $mobile) {
        //     font-size: 14px !important;
        // }
    }
    span {
        color: $colorGray;
    }
}
.hide {
    display: none;
}
main {
    overflow: hidden;
    font-family: $fontRaleway;
    font-weight: 400;
    font-style: normal;
    background-color: $colorWhite;
    // padding-top: 120px;
    // @media screen and (max-width: 1419px) {
    //     padding-top: 106px;
    // }
}

b {
    font-family: $fontRaleway;
}

strong {
    font-family: $fontRaleway;
}

h1 {
    font-family: $fontLato;
    line-height: 1;
}
h2 {
    font-family: $fontLato;
    @include fontSize30px;
    color: $colorGray;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
}
h3 {
    line-height: 1;
    font-family: $fontLato;
    color: $colorGray;
    font-weight: 400;
    @include fontSize25px;
}
.headerBright {
    color: $colorWhite;
}

.social {
    width: 90px;
    height: 32px;
    display: flex;
    position: relative;
    align-items: center;
    margin: 0 0 0 35px;
    ul {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
            a {
                position: absolute;
                display: block;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 32px 32px;
                height: 32px;
                width: 32px;
            }
            &:nth-of-type(1) {
                a {
                    left: 0;
                    top: 0;
                    background-image: url(assets/img/svg/icon-inst.svg);
                }
            }
            &:nth-of-type(2) {
                a {
                    left: 29px;
                    top: 0;
                    background-image: url(assets/img/svg/icon-fb.svg);
                }
            }
            &:nth-of-type(3) {
                a {
                    left: 58px;
                    top: 0;
                    background-image: url(assets/img/svg/icon-yt.svg);
                }
            }
        }
    }
}
.col4 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    // height: 100%;
    .box {
        width: calc((100% - 30px) / 3);
        padding: 60px;
    }
    @media screen and (max-width: $tablet) {
        .box {
            padding: 30px;
        }
        .colorful {
            min-height: auto;
            padding: 20px;
            flex-direction: column;
        }
    }
    @media screen and (max-width: $mobile) {
        flex-direction: column;
        gap: 15px;
        .colorful {
            padding: 10px 20px;
            width: 100%;
        }
    }
}

.flex3,
.flex4 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    flex-wrap: wrap;
    article {
        position: relative;
        background: $colorGreyF9;
        .photo {
            // position: relative;
            overflow: hidden;
        }
        a {
            // position: absolute;
            width: 100%;
            height: 100%;
            &:hover picture {
                transform: scale(1.5) rotate(25deg);
            }
            picture {
                width: 100%;
                height: auto;
                position: relative;
                background-size: cover;
                transition: transform 0.3s ease-in-out;
                background-position-x: right;
                img {
                    width: 100%;
                    height: auto;
                    visibility: hidden;
                }
            }
        }
        .text {
            padding: 15px;
            display: flex;
            align-items: center;
            flex-direction: column;
            h3 {
                @include fontSize25px;
                text-transform: uppercase;
                font-weight: 300;
            }
            .goTo {
                margin: 0 auto;
                background: $colorDark;
                color: $colorWhite;
                padding: 20px;
                transition: 0.3s ease-in all;
                width: fit-content;
                margin-top: 15px;
                text-align: center;
                &:hover {
                    color: $colorDark;
                    background-color: $colorYellow;
                }
            }
        }
    }
}
.flex3 {
    article {
        width: calc((100% - 60px) / 3);
        @media screen and (max-width: $smalltablet) {
            width: calc((100% - 30px) / 2);
        }
        @media screen and (max-width: $mobile) {
            width: 100%;
        }
    }
}
.flex4 {
    article {
        width: calc((100% - 90px) / 4);
        @media screen and (max-width: $smalltablet) {
            width: calc((100% - 30px) / 2);
        }
        @media screen and (max-width: $mobile) {
            width: 100%;
        }
    }
}
.events {
    &.spacer {
        @include padding100Bottom;
    }
    &.people {
        h3 {
            text-align: center;
            font-weight: 300;
            @include fontSize25px;
            white-space: pre-line;
            padding: 0;
        }
    }
    .archive {
        @include padding40Top;
    }
    h3 {
        @include fontSize25px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: left;
    }
    .event {
        position: relative;
        overflow: hidden;
        &:hover {
            .picture {
                .overlay {
                    opacity: 1;
                    top: 0;
                }
            }
            a > header {
                visibility: hidden;
            }
        }
        p {
            @include fontSize14px;
        }
        span {
            @include fontSize16px;
            font-style: italic;
            display: block;
        }
        a {
            width: 100%;
            z-index: 9;
            &:hover {
                p {
                    top: 50%;
                }
            }
        }
        .picture {
            position: relative;
            overflow: hidden;
            margin-bottom: 15px;
            .overlay {
                content: "";
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-color: rgba($color: $colorGrey3C, $alpha: 0.6);
                width: 100%;
                height: 100%;
                padding: 15px;
                mix-blend-mode: hard-light;
                z-index: 99;
                transition: 0.3s ease;
                top: 80px;
                bottom: 0;
                opacity: 0;
                left: 0;
                header {
                    h2 {
                        color: $colorWhite;
                    }
                    span {
                        color: $colorWhite;
                    }
                }
                .social {
                    display: flex;
                    gap: 5px;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    width: 100%;
                    margin: 0;
                }
                a {
                    position: relative;
                    display: block;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 28px 28px;
                    height: 28px;
                    width: 28px;
                    &.insta {
                        background-image: url(@/assets/img/svg/icon-inst.svg);
                    }
                    &.fb {
                        background-image: url(@/assets/img/svg/icon-fb.svg);
                    }
                    &.linkedin {
                        background-image: url(@/assets/img/svg/icon-yt.svg);
                    }
                }
            }
            picture {
                background-color: white;
                display: flex;
                justify-content: center;
                width: 100%;
                // img {
                //     max-height: 340px;
                //     max-width: 380px;
                // }
            }
        }
    }
}

.textContent {
    @include padding50Bottom;
    h3 {
        @include fontSize20px;
    }
    p {
        display: block;
        width: 100%;
    }
    p > img {
        display: inline;
    }
    p {
        a {
            color: #ab1456;
            transition: 0.3s ease-in all;
            &:hover {
                color: $colorDark;
            }
        }
        &.element__item {
            @media screen and (max-width: $smalltablet) {
                img {
                    width: 100% !important;
                    float: none !important;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }
            }
        }
    }
}

.navbarMobile {
    background-color: $colorWhite;
    height: 100vh;
    overflow-y: auto;
    overflow-y: auto;
    z-index: 10000000;
    right: auto;
    left: 100vw;
    padding-bottom: 60px;

    .container {
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        // @media screen and (max-width: $smalltablet) {
        //     max-width: calc(100% - 50px);
        // }
    }
    .close {
        width: 100%;
        height: 80px;
        position: absolute;
        top: 0;
        left: 0;
        button {
            z-index: 99;
            background: url(@/assets/img/svg/hamburger-close-42x42.svg) center
                center no-repeat;
            background-size: 30px auto;
            width: 80px;
            height: 80px;
            top: 0;
            right: 0;
            border: none;
            position: absolute;
            cursor: pointer;
            font-family: $fontRaleway;
        }
        @media screen and (max-width: $mobile) {
            height: 51px;
            button {
                width: 51px;
                height: 51px;
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
        position: absolute;
        top: 80px;
        left: 0;
        // background: $whiteFE;
        visibility: hidden;

        &.show {
            visibility: visible;
            ul {
                visibility: hidden;
            }
        }
        li {
            margin: 0 15px;
            margin-bottom: 10px;
            padding: 0;
            display: flex;
            justify-content: space-between;
            background: $colorGreye4;
            &:last-child {
                border: none;
            }
            button {
                @include button;
                display: block;
                position: relative;
                width: 49px;
                border-left: 5px solid $colorWhite;
                background-image: url(@/assets/img/svg/icon-arrow-right.svg);
                background-size: 7px auto;
                background-repeat: no-repeat;
                background-position: right 17px center;
                background-color: transparent;
                padding: 10px;
                cursor: pointer;
                font-family: $fontRaleway;
            }
            .back {
                cursor: pointer;
                font-family: $fontRaleway;
                button {
                    font-family: $fontRaleway;
                    background: url(@/assets/img/svg/icon-arrow-left.svg) left
                        17px center no-repeat;
                    background-size: 7px auto;
                    display: block;
                    background-color: transparent;
                    padding: 15px 15px 15px 40px;
                    border: none;
                    width: 100%;
                    cursor: pointer;
                    @include fontSize16px;
                    text-align: left;
                    // color: $colorBlueDark;
                }
            }
            // & > a.active {
            //     background: linear-gradient(45deg, #6dbe45 0%, #00497b 100%);
            //     font-weight: bold;
            // }
            a {
                padding: 15px 50px 15px 15px;
                position: relative;
                display: block;
                color: $colorGray;
                @include fontSize16px;
                flex-grow: 1;
            }
            ul {
                top: 0;
                li {
                    a.active {
                        background: none;
                    }
                }
            }
        }
    }
}

.news {
    @include padding80;
    &__articles {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 30px;
        article {
            display: flex;
            align-items: flex-start;
            gap: 15px;
            position: relative;
            .article__picture {
                width: 40%;
            }
            ul {
                list-style: none;
                display: flex;
                gap: 5px;
                padding: 0;
                margin: 0;
                padding: 10px 0;
                li {
                    a {
                        color: $colorWhite;
                        height: 100%;
                        background: $colorGreen;
                        padding: 10px;
                        transition: 0.3s ease-in all;
                        &:hover {
                            opacity: 0.7;

                        }
                    }
                }
            }
            .article__text {
                height: 100%;
                position: relative;
                width: 60%;
                gap: 15px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                h2 {
                    text-align: left;
                    transition: 0.2s ease-in color;
                }
                div {
                    p {
                        text-align: left;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        padding: 0;
                        @media screen and (max-width: $smalltablet) {
                            -webkit-line-clamp: 2;
                        }
                        @media screen and (max-width: $mobile) {
                            display: none;
                        }
                    }
                }
                &:hover {
                    h2 {
                        color: $colorMain;
                    }
                }
            }
            .more {
                text-transform: uppercase;
                font-weight: 700;
                transition: 0.3s ease-in all;
                position: absolute;
                bottom: 0;
                @include fontSize18px;
                padding-right: 20px;
                // background: url(@/assets/img/svg/icon-arrow-right.svg) right bottom no-repeat;
                // background-size: 8px;
                transition: 0.3s ease-in all;
                &:hover {
                    padding-right: 30px;
                }
            }
            @media screen and (max-width: $mobile) {
                flex-direction: column;
                .article__picture {
                    width: 100%;
                }
                .article__text {
                    width: 100%;
                }
                h2 {
                    padding: 0;
                }
                .more {
                    padding-right: 0;
                }
            }
        }
    }
    &__right-side {
        background-color: $colorGreye4;
        display: flex;
        flex-direction: column;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        height: auto;
        position: relative;
        z-index: 1;
        &::after {
            content: '';
            position: absolute;
            width: 100vw;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $colorGreye4;
            z-index: -1;
        }
        .fixed__form {
            z-index: 2;
            legend {
                display: none;
            }
            fieldset {
                display: flex;
                flex-wrap: wrap;
                gap: 10px 0;
                label {
                    width: 100%;
                    @include fontSize20px;
                    font-weight: 500;
                }
                input[type=search] {
                    width: calc(100% - 52px);
                    border: none;
                    padding: 16px 20px;
                    font-family: $fontLato;
                    font-size: 16px;
                }
                input[type=submit] {
                    width: 52px;
                    background: url(@/assets/img/svg/icon-magnifier.svg) center center no-repeat $colorMain;
                    background-size: 26px auto;
                    padding: 16px 20px;
                    color: transparent;
                    font-size: 13px;
                    transition: 0.3s ease-in all;
                    &:hover {
                        background-color: $colorGray;
                    }
                }

            }
        }
        .categories {
            padding-top: 40px;
            z-index: 2;
            h3 {
                @include fontSize20px;
            }
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                @media screen and (max-width: $tablet) {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px 15px;
                }
                li {
                    padding: 3px 0;
                    border-bottom: 1px solid $colorGreyA5;
                    &:last-of-type {
                        @media screen and (min-width: $tablet) {
                            border-bottom: none;
                        }
                    }
                }
            }
            a {
                transition: 0.3s ease-in all;
                &:hover, &.active {
                    color: $colorGreyA5;
                }
            }
        }
    }
}
</style>
