<template>
    <ul>
        <!-- <li v-for="(item, index) in newsCategories" :key="index"><a :href="item.href">{{ item.title }}</a></li> -->
        <!-- <li v-for="(item, index) in newsCategories" :key="index"><router-link :to="item.href">{{ item.title }}</router-link></li> -->
        <li v-for="(item, index) in newsCategories" :key="index">
            <router-link :to="`${item.href}#newsHeader`" v-if="item.href != ''">{{ item.title }}</router-link>
        </li>
    </ul>
</template>
<script>
import axios from "axios";

export default {
    data() {
        return {
            newsCategories: [],
        };
    },
    name: "Kategorie-aktualnosci",
    components: {},
    props: ["pageData"],
    methods: {
        getNewsCategories() {
            // axios.get("/api/newsList/newsList.php").then((response) => {
            axios.get("https://rzeszowska.org.pl/api/newsCategories/newsCategories.php").then((response) => {
                // axios.get("http://localhost:3000/newsCategories").then((response) => {
                this.newsCategories = response.data;
            });
        },
    },
    mounted() {
        this.getNewsCategories();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.bottom__box {
    &--categories {
        ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0 10px;
            width: 100%;
            li {
                a {
                    position: relative;
                    &:after {
                        content: "\2022";
                        // position: absolute;
                        width: 15px;
                        // height: 5px;
                        @include fontSize14px;
                        bottom: -3px;
                        right: -8px;
                        text-align: center;
                        left: unset;
                        display: none;
                    }
                }
                &:last-of-type {
                    a {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
            @media screen and (max-width: $smalltablet) {
                grid-template-columns: 100%;
                display: flex;
                flex-wrap: wrap;
                li {
                    a {
                        &:after {
                            display: inline-block;
                        }
                    }
                }
            }
            // @media screen and (max-width: $mobile) {
            //     display: none;
            // }
        }
    }
}
</style>
