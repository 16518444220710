import { render, staticRenderFns } from "./KategorieAktualnosci.vue?vue&type=template&id=010b5386&scoped=true&"
import script from "./KategorieAktualnosci.vue?vue&type=script&lang=js&"
export * from "./KategorieAktualnosci.vue?vue&type=script&lang=js&"
import style0 from "./KategorieAktualnosci.vue?vue&type=style&index=0&id=010b5386&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "010b5386",
  null
  
)

export default component.exports