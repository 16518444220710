<template>
    <footer class="bottom">
        <div class="container bottom-container">
            <div class="row row30">
                <div class="col col4 bottom__box fade fadein fadeinstart">
                    <div class="brand">
                        <div>
                            <picture><img src="@/assets/img/svg/small-logo.svg" alt="" /></picture>
                            <p>
                                Sięgamy do korzeni, odkrywamy, inspirujemy, tworzymy, wspieramy, zmieniamy świat na lepszy. Podejmujemy inicjatywy kulturalne, artystyczne, społeczne i edukacyjne z myślą o rozwoju tożsamości regionalnej w Rzeszowie i
                                województwie podkarpackim.
                            </p>
                        </div>
                        <div>
                            <h2>Fundacja Rzeszowska</h2>
                            <div class="address">
                                <p>+48 737 900 850</p>
                                <p>fundacja(at)rzeszowska.org.pl</p>
                                <p>Rzeszów 35-051, ul. Lenartowicza 10/42</p>
                                <p>NIP: 8133713654 KRS: 0000596573 Regon: 363520936 BNP Paribas: 02 1750 0012 0000 0000 3209 4748</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col4 bottom__box bottom__box--categories fade fadein fadeinstart">
                    <h2>Kategorie</h2>
                    <KategorieAktualnosci></KategorieAktualnosci>
                </div>
                <div class="col col4 bottom__box bottom__box--news fade fadein fadeinstart">
                    <h2>Ostatnie posty</h2>
                    <ul>
                        <li v-for="(item, index) in lastNews" :key="index">
                            <picture><img :src="item.imagePath" :alt="item.title" /></picture>
                            <router-link :to="item.link"
                                >{{ item.title }}
                                <span>{{ item.date }}</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <!-- <div class="bottom__box col col3">
                    
                </div> -->
            </div>
        </div>
        <div class="realization">
            <p>Realizacja: <a href="https://zdzislowicz.pl" title="Serwisy WWW">Zdzislowicz.pl</a></p>
        </div>
    </footer>
</template>

<script>
import axios from "axios";
import KategorieAktualnosci from "@/components/KategorieAktualnosci";

export default {
    data() {
        return {
            menu: [],
            newsList: [],
            newsCategories: null,
        };
    },
    name: "Footer-section",
    props: ["pageData"],
    components: {
        KategorieAktualnosci: KategorieAktualnosci,
        // KategorieAktualnosci: () => import('./KategorieAktualnosci.vue')
    },
    computed: {
        lastNews() {
            const getlastNews = this.newsList.slice(0, 4);
            return getlastNews;
        },
    },
    methods: {
        getNewsList() {
            // axios.get("/api/newsList/newsList.php").then((response) => {
            axios.get("https://rzeszowska.org.pl/api/newsList/newsList.php").then((response) => {
                // axios.get("http://localhost:3000/newsList").then((response) => {
                this.newsList = response.data;
            });
        },
    },
    mounted() {
        this.getNewsList();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// @import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

$bigtablet: 1279px;
$tablet: 1023px;
$smalltablet: 767px;
$mobile: 479px;

.realization {
    color: $colorGray;
    width: 100vw;
    z-index: 999;
    text-align: right;
    background: $colorGray;
    color: $colorWhite;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 30px;
    position: relative;
    height: 44px;
    bottom: -20px;
    left: -15px;
    position: relative;
    p,
    a {
        color: $colorWhite;
        padding: 0;
        @include fontSize13px;
        transition: 0.2s ease-in color;
    }
    a:hover {
        color: $colorYellow;
    }
}

footer {
    background-image: url(../assets/img/the-fog.jpg);
    background-position: bottom;
    background-color: rgba($color: #fefefe, $alpha: 1);
    padding: 20px 15px;
    position: relative;
    overflow: hidden;
    &:before {
        content: "";
        background-color: rgba($color: $colorWhite, $alpha: 0.75);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }
    .container {
        display: flex;
        position: relative;
        justify-content: space-between;
        z-index: 2;
        a,
        p {
            @include fontSize14px;
            color: $colorGray;
            padding: 0;
            text-align: left;
        }
    }
    .bottom__box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .brand {
            display: flex;
            flex-direction: column;
            gap: 30px;
            picture {
                display: flex;
                justify-content: center;
                width: 100%;
                height: auto;
                position: relative;
                img {
                    width: 100%;
                    max-width: 150px;
                    height: auto;
                }
            }
            @media screen and (max-width: $tablet) {
                flex-direction: row;
                align-items: center;
                & > div {
                    width: 50%;
                }
            }
            @media screen and (max-width: $mobile) {
                flex-direction: column;
                & > div {
                    width: 100%;
                }
            }
        }
        h2 {
            @include fontSize20px;
            text-transform: none;
            font-weight: 600;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            text-align: left;
            li {
                a {
                    color: $colorGray;
                    transition: 0.3s ease-in all;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
        &--news {
            li {
                display: flex;
                align-items: center;
                &:nth-last-of-type(n + 2) {
                    margin-bottom: 10px;
                }
                span {
                    display: block;
                }
            }
            picture {
                position: relative;
                width: 50px;
                height: 50px;
                min-width: 50px;
                margin-right: 20px;
                overflow: hidden;
                padding: 0;
                border-radius: 50%;
                img {
                    // border-radius: 50%;
                    // width: 50px;
                    // height: 50px;
                    width: auto;
                    height: 100%;
                }
            }
        }
        .address {
            p {
                color: $colorGray;
                a {
                    color: $colorGray;
                }
            }
        }
    }
    @media screen and (max-width: $smalltablet) {
        padding: 15px;
        .container {
            flex-direction: column;
            align-items: center;
            gap: 7px;
        }
    }
}
</style>
