<template>
    <section class="textContent" v-if="news">
        <header
            class="subpage-header subpage-header--big"
        >
            <h1 class="fade fadein fadeinstart">{{ news.title }}</h1>
        </header>
        <div class="container">
            <div class="row row60">
                <div class="col col8 news__articles fade fadein fadeinstart">
                    <header class="header fade fadein fadeinstart">
                        
                        <span class="date"> {{ news.date }} </span>
                    </header>
                    <div class="singleNews__content fade fadein fadeinstart" v-html="news.content"></div>
                    <!-- <picture>
                        <img :src="`/upload/pictures/normal/${news.imagePath}`" alt="" />
                    </picture> -->
                    <Video v-if="news.video" :videoItems="videoAktualnosci"></Video>
                    <Gallery v-if="gallery" :galleryItems="zmodyfikowaneDaneDoGalerii" />
                </div>
                <div class="col col4 news__right-side fade fadeinright fadeinrightstart">
                    <WyszukiwarkaModul></WyszukiwarkaModul>
                    <div class="categories">
                        <header><h3>Kategorie wpisów</h3></header>
                        <KategorieAktualnosci></KategorieAktualnosci>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import WyszukiwarkaModul from "@/components/WyszukiwarkaModul";
import KategorieAktualnosci from "@/components/KategorieAktualnosci";
import Video from "@/components/Video";
import Gallery from "@/components/Gallery";

export default {
    data() {
        return {
            news: [],
            gallery: []
        };
    },
    name: "Wpis-aktualnosci",
    components: {
        WyszukiwarkaModul: WyszukiwarkaModul,
        KategorieAktualnosci: KategorieAktualnosci,
        Video: Video,
        Gallery: Gallery,
    },
    computed: {
        zmodyfikowaneDaneDoGalerii() {
            const currentGalleryData = this.gallery;
            const newGalleryData = [];
            currentGalleryData.forEach((item) => {
                const newItem = {
                    imagePath: `upload/pictures/${item.zdjecie_sciezka}.webp`,
                    imagePathMobile: `upload/pictures/mobile/${item.zdjecie_sciezka}.webp`,
                    imagePathNormal: `upload/pictures/normal/${item.zdjecie_sciezka}.webp`,
                    imageAlt: item.alt,
                    imageTitle: item.title
                };
                newGalleryData.push(newItem);
            });
            return newGalleryData;
        },
        videoAktualnosci() {
            const dataWpis = this.pageData.video;
            return dataWpis;
        },
    },
    props: ["pageData"],
    methods: {
        getNewsItem() {
            axios.get("https://rzeszowska.org.pl/api/newsList/newsSingle.php?id=" + this.pageData.id).then((response) => {
            // axios.get("http://localhost:3000/newsList").then((response) => {
                this.news = response.data.news;
                this.gallery = response.data.gallery;
            });
        },
    },
    mounted() {
        this.getNewsItem();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

// .textContent {
//     .singleNews {
//         &__content {
//             // & > picture {
//             //     width: 100%;
//             //     height: auto;
//             //     margin-bottom: 50px;
//             //     img {
//             //         width: 100%;
//             //         height: auto;
//             //     }
//             // }
//             p > img {
//                 width: 100%;
//                 height: auto;
//                 // margin: 15px 0;
//                 display: inline;
//             }
//             img {
//                 // width: 100%;
//                 // height: auto;
//                 margin: 15px 0;
//             }
//         }
//     }
// }
</style>
